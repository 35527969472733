.progressBox{
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999999999;
    width: 250px;
    padding: 15px 15px;
    background-color: #f4f4f4;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.progressBox p{
    font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #747679;
  white-space: nowrap;
  margin-bottom: 5px;
}
.progressBox h6{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #5e5e5e;
    white-space: nowrap;
    margin-bottom: 5px;
}