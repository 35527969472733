.adminPage {
  min-height: 100vh;
  padding-top: 80px;
  background-color: #f8f8f8;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 30px;
}

.adminHeading {
  display: flex;
  justify-content: space-between;
  padding: 22px 30px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px #0000001a;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.adminHeading h2 {
  font-family: "Inter";
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
  margin-bottom: 0px;
}

.currentPlan {
  min-height: 214px;
  margin-top: 25px;
  margin-right: 15px;
  padding: 13px 30px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px #0000001a;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.currentPlan h2{
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
  margin-bottom: 0px;
}

.currentPlan .tableBody tbody td:last-child{
  text-align: left;
}

.planHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.planHeading h2 {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
  margin-bottom: 0px;
}

.planHeading a ,.planHeading button{
  background: #f94a29;
  color: #ffffff;
  font-family: "Inter";
  padding: 10px 20px;
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  text-decoration: none;
}

.contactAddBtn {
  display: flex;
}

.planList {
  padding: 0px 20px;
}

.planDescription {
  margin-top: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.planDescription p {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #a0a0a0;
  width: 120px;
  margin-bottom: 0px;
}

.planDescription .planName {
  background: #f6f7f8;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #747679;
  padding: 9px 15px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  width: 200px;
}

.storagePlan {
  min-height: 214px;
  margin-top: 25px;
  margin-left: 15px;
  padding: 13px 30px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px #0000001a;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

/* Custom class for progress color */
.custom-progress-bar-progress {
  background-color: #dfe1e5 !important;
  height: 25px !important;
  border-radius: 25px !important;
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  -ms-border-radius: 25px !important;
  -o-border-radius: 25px !important;
}

.storageProgress {
  margin-top: 15px;
}

.storageProgress p {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
}

.storageProgress .progress-bar {
  background-color: #f94a29 !important;
}

.storageProgress h5 {
  margin-top: 12px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #a0a0a0;
}

.storageProgress a {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #f94a29;
  display: block;
}

.userTableBox {
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px #0000001a;
  padding: 14px 30px;
  margin-top: 25px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.userHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userHeading h1 {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
}

.userHeading button {
  background: #f94a29;
  color: #ffffff;
  font-family: "Inter";
  margin-left: 10px;
  padding: 10px 20px;
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.adminListTable {
  height: calc(100vh - 565px);
  padding: 0px 5px;
  overflow-y: auto;
}

.editContactBtn {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.editContactBtn button {
  padding: 12px 25px;
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.editContactBtn .back {
  border: 2px solid #F94A29;
  color: #F94A29;
  background-color: #fff;
}
.editContactBtn .delete {
  border: 2px solid #E11105;
  color: #ffffff;
  background: #E11105;
}
.editContactBtn .save {
  border: 2px solid #F94A29;
  color: #ffffff;
  background: #F94A29;

}

.registrationInput .userRoleSelect {
  cursor: no-drop;
  pointer-events: none;
  background-color: #eaeaea;
}

.blankBtn{
  background-color: #e6e6e6 !important;
  cursor: none !important;
  color: #cc7665 !important;
}

@media (max-width: 778px) {
  .storagePlan {
    margin-left: 0px;
  }

  .currentPlan {
    margin-right: 0px;
  }

  .adminPage {
    padding-left: 0px;
    padding-right: 0px;
  }

  .adminHeading h2 {
    font-size: 15px;
  }

  .currentPlan,
  .storagePlan,
  .userTableBox {
    padding: 13px 15px;
  }

  .planDescription p {
    margin-bottom: 5px;
  }

  .userHeading {
    justify-content: center;
    flex-wrap: wrap;
  }

  .contactAddBtn {
    flex-wrap: wrap;
    justify-content: center;
  }

  .userHeading button {
    margin-top: 15px;
  }

  .addContactForm {
    padding: 30px 0px;
  }

  .addContactBtn {
    flex-wrap: wrap;
    justify-content: center;
  }

  .addContact {
    margin-top: 15px;
  }
}