/* banner start */
.bannerSection {
    background: #FFEFEC;
    padding-top: 130px;
    padding-bottom: 80px;
}

.leftBanner {
    padding-right: 70px;
}

.leftBanner h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 54px;
    color: #303030;
}

.leftBanner p {
    margin-top: 15px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #303030;
}

.rightBanner img {
    width: 100%;
}



/* banner end */

/* company description start */
.CompanyInfo{
   padding-bottom: 120px;
}
.companyHeading {
    margin-bottom: 80px;
}

.companyHeading h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 30px;
    text-align: center;
    color: #303030;
}

.CompanyCard {
    margin-bottom: 15px;
}

.CompanyCard .IconImg {
    height: 140px;
}

.CompanyCard h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #F94A29;
    margin-top: 10px;
}

.CompanyCard p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #303030;
    margin-bottom: 5px;
}

.CompanyCard a {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-decoration-line: underline;
    color: #F94A29;
}



/* company description end */

/* company logo start */

.CompanyList {
    padding-top: 40px;
    padding-bottom: 60px;
}

.CompanyIcon {
    margin-bottom: 25px;
}

.CompanyIcon h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 30px;
    text-align: center;
    color: #303030;
}





/* company end */

/* tutorial start */
.tutorialSection {
    padding: 150px 0px;
}

.tutorialVideo h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 30px;
    text-align: center;
    color: #303030;
}

.tutorialVideo p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #303030;
}

.video {
    margin-top: 40px;
}



/* tutorial end */

/* stream power start */
.StreamPower {
    padding: 80px 0px;
}

.powerHeading {
    margin-bottom: 55px;
}

.powerHeading h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 30px;
    color: #303030;
}

.powerHeading p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: rgba(48, 48, 48, 0.7);
}

.powerDescription {
    background: #FFFFFF;
    padding: 8px 0px;
}

.powerDescription h1,
.powerDescriptionActive h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #303030;
    margin-bottom: 0px;
}

.powerDescription p,
.powerDescriptionActive p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
}

.videoBox {
    height: 100%;
}

.videoBox img {
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 15px 2px #00000026;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.powerNav.active {
    background: #FFFFFF !important;
    border-left: 7px solid #F94A29 !important;
    border-radius: 4px 0px 0px 4px !important;
    padding: 8px 13px !important;
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.15) !important;
    margin-bottom: 15px !important;
    -webkit-border-radius: 4px 0px 0px 4px !important;
    -moz-border-radius: 4px 0px 0px 4px !important;
    -ms-border-radius: 4px 0px 0px 4px !important;
    -o-border-radius: 4px 0px 0px 4px !important;
}

.flex-column .nav-item a {
    background-color: #fff;
    margin-bottom: 15px;
}

.tabRightVideo {
    height: 100% !important;
}

.tabRight {
    height: 100% !important;
}

/* stream power end */

/* stream line start */
.streamLine {
    padding: 100px 0px;
    background: #FFEFEC;
}

.streamLineHeading {
    margin-bottom: 65px;
}

.streamLineHeading h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 30px;
    color: #303030;
}

.streamLineHeading p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: rgba(48, 48, 48, 0.7);
}

.streamCard {
    width: 100%;
    height: 310px;
    background-color: #fff;
    position: relative;
    padding: 20px;
    border-radius: 15px;
}

.streamCard .sideImage {
    position: absolute;
    top: 0px;
    left: 0px;
    width: auto !important;
}

.streamCard .carouselIcon {
    position: absolute;
    top: 15px;
    width: auto !important;
}

.streamCard h1 {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #303030;
}

.streamCard p {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
}

.streamInfo {
    margin-top: 55%;
}
.streamNav .nav-item{
    max-width: 380px;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
    background: none !important;
    color: #FFF;
    text-decoration: none;
}

.customNav .owl-nav {
    position: absolute;
    top: -110px;
    right: 20px;
}

.customNav .owl-prev .prev,
.customNav .owl-next .next {
    font-size: 25px;
    color: #303030;
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    height: 50px;
    width: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.customNav .owl-prev {
    margin: 0px 30px !important;
}



/* stream line end */

/* price start */
.priceTable {
    padding: 80px 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.priceHeading {
    margin-bottom: 75px;
}

.priceHeading h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #303030;
}

.priceBox {
    background: #FFEFEC;
    border-radius: 5px;
    padding: 25px 0px;
    min-height: 670px;
    text-align: center;
    position: relative;
}

.priceBox .priceStart{
    position: absolute;
    bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.priceBox .priceStart a{
    background: rgb(249, 74, 41);
  box-shadow: rgba(0, 0, 0, 0.15) 2px 6px 20px 2px;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgb(255, 255, 255);
  padding: 14px 30px;
  border: 1px solid rgb(249, 74, 41);
  text-decoration: none;
}

.packageLow {
    padding: 86px 0px;
}
.learnMoreBtn a{
  background: #f94a29;
  box-shadow: 2px 6px 20px 2px rgba(0,0,0,.15);
  color: #fff;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  border: 1px solid #f94a29;
  display: inline-block;
  margin-top: 70px;
  padding: 14px 30px;
  text-decoration: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.packageInfo p,
.packageLow p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    text-align: center;
    color: #303030;
}

.packageInfo,
.packageLow {
    text-align: center;
}

.packageInfo p svg,
.packageLow p svg {
    color: #F94A29;
    padding-right: 5px;
}

.packageInfo p span,
.packageLow p span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #303030;
}

.enterprisePackage h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #303030;
    margin-top: 15px;
    margin-bottom: 35px;
}

.RecommendedBox {
    background: #FFFFFF;
    border: 3px solid #F94A29;
    box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    min-height: 720px;
    margin-top: -20px;
}

.RecommendedBox .packageName {
    margin-top: 15px;
}

/* pricing tab */
.priceTab {
    margin-bottom: 100px !important;
    justify-content: center !important;
    border: none !important;
    border: none !important;
    width: 286px !important;
    background-color: #fff !important;
    padding: 10px;
    margin-left: 50%;
    transform: translate(-50%, 0);
    box-shadow: 2px 6px 20px 2px rgba(0, 0, 0, 0.15);
}

.priceTab .nav-item button {
    background-color: #fff !important;
    border-radius: 5px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    color: #303030 !important;
    padding: 14px 30px;
    border: none;
    border: #F94A29;
}

.priceTab .nav-item .active {
    background-color: #F94A29 !important;
    color: #fff !important;
}

.priceTableBox {
    padding: 0px 20px !important;
    max-width: 1460px !important;
}

.recommendLine {
    position: absolute;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translate(-50%, 0);
}

.recommendLine p {
    background: #F94A29;
    box-shadow: 2px 6px 20px 2px rgba(0, 0, 0, 0.15);
    border-radius: 5px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 8px 21px;
}


/* price end */

.getStarted{
    margin-top: 10px;
    display: inline-block;
    background: rgb(249, 74, 41);
  box-shadow: rgba(0, 0, 0, 0.15) 2px 6px 20px 2px;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgb(255, 255, 255);
  padding: 14px 30px;
  border: 1px solid rgb(249, 74, 41);
  text-decoration: none;
}

@media (-webkit-min-device-pixel-ratio: 1) and (max-width:780px) {
    .companyHeading h1 {
        font-size: 28px;
    }
    .leftBanner {
        padding-right: 0px;
        padding-bottom: 40px;
    }

    .leftBanner h1 {
        font-size: 35px;
        line-height: 34px;
    }

    .bannerSection {
        background: #FFEFEC;
        padding-top: 110px;
        padding-bottom: 66px;
    }

    .getStarted {
        margin-left: 50%;
        transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
    }

   

    .CompanyCard .IconImg {
        height: 113px;
        margin-top: 37px;
        text-align: center;
    }

    .tutorialSection {
        padding: 80px 0px 0px 0px;
    }

    .customNav .owl-nav {
        position: absolute;
        top: -77px;
        right: 20px;
    }

    .RecommendedBox {
        margin: 40px 0px;
    }

    .priceHeading h1 {
        font-size: 25px;
        line-height: 35px;
    }
}

@media (-webkit-min-device-pixel-ratio: 1) and (max-width: 300px) {
    .priceTab {
        width: 260px !important;
    }
}

