.registrationStep {
    background: #F8F8F8;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 130px;
    padding-bottom: 90px;
}

.Register-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    padding: 5vh 5vh 5vh 0vh;
    background-color: 5% 0% 5% 5%;
}

.registerBox {
    display: flex;
    justify-content: space-between;
}

.registration-form {
    max-width: 520px;
    padding: 45px 65px 45px 54px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    box-shadow: 0px 0px 10px 0px #0000001A;
    margin-right: 15px;
}

.registerContent {
    width: 50%;
    background: #FFEFEC;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    min-height: 90vh;
    display: flex;
    padding: 20px 20px 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.registerForm {
    width: 50%;
    box-shadow: 0px 0px 10px 0px #0000001A;
    background-color: #fff;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.registrationHeading h1 {
    font-family: 'Inter';
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #303030;
}




.registrationInput {
    position: relative;
    margin-top: 11px;
}

.registrationInput h6 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #303030;
    display: block;
    text-align: center;
    margin-bottom: 20px;
}

.registrationInput input {
    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    border-radius: 5px;
    width: 100%;
    height: 45px;
    padding: 0px 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: rgb(54, 54, 55);
    position: relative;
}

.registrationInput label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #A0A0A0;
    margin-bottom: 3px;
}

.registrationInput span {
    position: absolute;
    right: 15px;
    color: #747679;
    font-size: 12px;
    cursor: pointer;
    /* top: 39px; */
}

.registrationInput .form-select {
    padding: 8px 20px 8px 20px !important;
}

.inputIcon {
    position: relative;
    display: flex;
    align-items: center;
}

.loginLink a {
    margin-top: 30px;
    background: #FFFFFF;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #F94A29;
    width: 100%;
    height: 50px;
    border: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.loginLink a:hover {
    border: 2px solid #F94A29;
}


.agreement span {
    font-family: 'Inter';
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #A0A0A0;
    cursor: pointer;
}

.agreement span a {
    color: #F94A29;
    text-decoration: underline;
}

.regisLoginBtn {
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.15) 2px 6px 20px 2px;
    border-radius: 5px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: rgb(249, 74, 41);
    padding: 14px 30px;
    border: 1px solid rgb(249, 74, 41);
    text-decoration: none;
}

.googleBtn {
    text-align: center;
    position: relative;
}

.googleBtn::before {
    content: "";
    width: 100%;
    height: 2px;
    background-color: #F94A29;
    position: absolute;
    display: block;
    margin-top: 24px;
    z-index: 9;
}

.googleBtn span {
    border: none;
    background-color: rgb(255, 255, 255);
    color: #F94A29;
    font-size: 25px;
    z-index: 999;
    position: relative;
}

.googleBtn span img {
    width: 50px;
    margin-top: 0px;
    z-index: 9999999;
    position: relative;
    cursor: pointer;
}
.readOnlyClass{
    background-color: rgba(231, 231, 231, 0.61) !important;
}
.preAgreement{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px !important;
    line-height: 17px;
    color: #A0A0A0;
    margin-bottom: 5px;
    margin-top: 30px;
}
.asterisk{
    color: #F94A29;
    font-size: 12px;
}
.registration-form .react-tel-input .form-control{
    width: 100% !important;
    height: 45px !important;
}
.registration-form .react-tel-input .form-control:focus{
    border: 1px solid #D2D2D2 !important;
}

@media (-webkit-min-device-pixel-ratio: 1) and (max-width:778px) {
    .Register-form-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 100vh;
        padding: 0vh;
        background-color: 5% 0% 5% 5%;
    }

    .registerBox {
        flex-wrap: wrap;
        padding: 15px;
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
    }

    .registerForm,
    .registerContent {
        width: 100%;
        height: 100%;
    }

    .registerForm {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .registerContent {
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
    }
}