.stepBox{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.stepHeading {
  font-family: "Inter";
  font-size: 30px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #303030;
}
.stepDescription {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #303030;
}
.MainContainer {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 16px;
}
.StepContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 70px;
  position: relative;
}

.StepContainer:before {
  content: "";
  position: absolute;
  background: #f3e7f3;
  height: 4px;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.StepContainer:after {
  content: "";
  position: absolute;
  background: #4a154b;
  height: 4px;
  width: var(--width);
  top: 50%;
  transition: 0.4s ease;
  transform: translateY(-50%);
  left: 0;
}

.StepWrapper {
  position: relative;
  z-index: 1;
}

.StepStyle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease;
}

.StepStyle.completed {
  border: 3px solid #4a154b;
}

.StepStyle.incomplete {
  border: 3px solid #f3e7f3;
}

.StepCount {
  font-size: 19px;
  color: #f3e7f3;
}

@media (max-width: 600px) {
  .StepCount {
    font-size: 16px;
  }
}

.StepsLabelContainer {
  position: absolute;
  top: 66px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.StepLabel {
  font-size: 19px;
  color: #4a154b;
}

@media (max-width: 600px) {
  .StepLabel {
    font-size: 16px;
  }
}

.ButtonsContainer {
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
  margin-top: 100px;
}

.ButtonStyle {
  border-radius: 4px;
  border: 0;
  background: #4a154b;
  color: #ffffff;
  cursor: pointer;
  padding: 8px;
  width: 90px;
}

.ButtonStyle:active {
  transform: scale(0.98);
}

.ButtonStyle:disabled {
  background: #f3e7f3;
  color: #000000;
  cursor: not-allowed;
}

.CheckMark {
  font-size: 26px;
  font-weight: 600;
  color: #4a154b;
  -ms-transform: scaleX(-1) rotate(-46deg);
  -webkit-transform: scaleX(-1) rotate(-46deg);
  transform: scaleX(-1) rotate(-46deg);
}
