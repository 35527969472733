.settings h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #303030;
}

.calenderBox {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #D2D2D2;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 9px 20px;
  color: rgba(116, 118, 121, 1);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.calenderShowBox {
  max-width: 150px !important;
  position: relative;
  display: flex;
  align-items: center;
  background: #f6f7f8;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 0px 0px;
  color: rgba(116, 118, 121, 1);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.requestInputForm .react-datepicker-wrapper {
  width: 100%;
}

.calenderBox img {
  position: absolute;
  right: 20px;
}

.dateInput input {
  width: 100%;
  position: relative;
}

.customMsgShow textarea {
  width: 100%;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  height: 170px;
  padding: 10px;
  color: rgba(116, 118, 121, 1);
}

.customMsgShow label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a0a0a0;
}

.scheduleBtn button {
  background: #d5d6d7;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
}

.fileIcon img {
  width: 21px;
  margin: 0px 5px;
}

.createDropBox {
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 25px;
  position: relative;
}

.sendListNumber {
  background: #f94a29;
  border-radius: 2px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  padding: 6px 14px;
  border: none;
  letter-spacing: 1px;
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.addEmployBtn {
  background: #f94a29;
  border-radius: 2px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  padding: 6px 14px;
  border: none;
  letter-spacing: 1px;
  position: absolute;
  bottom: 15px;
  left: 15px;
}


.createDropBox h1,
.createLeftBox h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #303030;
}

.sentUpload .table-responsive {
  max-height: 500px;
  overflow-y: auto;
}

.itemSerialList {
  height: 40vh;
  overflow-y: auto;
  padding-top: 10px;
}

.itemSerialList .modalItem {
  position: relative;
  margin-bottom: 18px;
}

.itemSerialList p {
  width: 100%;
  pointer-events: none;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #747679;
  padding: 10px 15px;
  margin-bottom: 8px;
  border: none;
  background: #f6f7f8;
}

.factorTable .table-responsive {
  height: 60vh;
  overflow-y: auto;
}

/* .factorTable table tbody tr td:nth-child(2) .authToggle,
.factorTable table tbody tr td:nth-child(4) {
    display: flex;
    justify-content: center;
} */

.factorTable .tableBody tbody td:last-child {
  display: flex;
  justify-content: center;
}

.react-tel-input {
  width: auto !important;
}

.sendCustomMsg {
  height: 160px;
  overflow-y: auto;
  width: 100%;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #747679;
  padding: 8px 10px;
  border: none;
  background: #f6f7f8;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.requestInput .itemSerialList {
  height: 58vh;
  overflow-y: auto;
  padding-top: 10px;
}

.nameText {
  white-space: nowrap;
}

.sentTable tbody tr td {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.sentTable thead tr th {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #747679;
}

.girdView {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.gridBox {
  width: 250px;
  background: #f6f7f8;
  position: relative;
  padding: 10px;
  margin-right: 25px;
  margin-bottom: 25px;
  box-shadow: 0px 0px 5px 0px #00000026;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.gridBox img {
  width: 100%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.gridBox p {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #747679;
  margin-top: 16px;
}

.gridBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gridBox .gridCheck {
  position: absolute;
  top: 19px;
  left: 22px;
}

.imageHeading {
  height: 230px;
  background: #00000033;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  cursor: pointer;
}

.imageHeading .notFound {
  width: 50px;
}

.imageHeading .found {
  width: 100%;
  height: 100%;
}

.imageHeading video {
  width: 100%;
  height: 100%;
}

.downloadFileSection {
  height: 100vh;
  padding: 30px 54px 50px 50px;
  background-color: #F8F8F8;
}

.listShowView {
  overflow-y: auto;
  position: relative;
  height: calc(100vh - 275px);
}

.downloadFileUpload {
  box-shadow: 0px 5px 20px 0px #d2dae3;
  z-index: 1;
  padding: 40px 30px;
  background-color: #fff;
  /* border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px; */
  /* height: calc(100vh - 110px); */
  height: calc(100vh - 110px);
  position: relative;
  margin-right: 20px;
}

.middleRequest {
  margin: 0px 15px;
}

.dueDateFormat {
  display: flex;
  align-items: center;
}

.dueDateFormat p {
  position: absolute;
  z-index: 99;
  margin-bottom: 0px;
  color: rgba(116, 118, 121, 1);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.dueDateFormat .calenderBox {
  height: 38px;
}

@media (-webkit-min-device-pixel-ratio: 1) and (max-width: 778px) {
  .recipientOption {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap !important;
  }

  .middleRequest {
    margin: 0px 0px;
  }

  .recipientOption .required {
    margin-top: 0px;
    width: auto;
  }

  .settings {
    margin-top: 15px;
  }

  .requestInput {
    margin-top: 15px;
  }

  .dateInput input {
    max-width: 100% !important;
  }

  .downloadFileSection {
    padding: 30px 15px 50px 15px;
  }

  .downloadFileUpload {
    margin-right: 0px;
  }
}