.createRequest {
    min-height: 100vh;
    padding-top: 80px !important;
    padding-left: 80px;
    padding-right: 80px;
    background-color: #F8F8F8;
}

.createLeftBox {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px 25px;
    min-height: calc(100vh - 210px);
}

.headingTitle h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #303030;
}

.errorMsg {
    text-align: left;
    font-size: 13px;
    color: #F94A29;
    font-weight: 600;
}

.errMsg {
    text-align: left;
    font-size: 13px !important;
    color: #F94A29 !important;
    margin-bottom: 0px;
}

.templateLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.templateHeading h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #303030;
}

.templateButton {
    display: flex;
}

.templateButton button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    background: #FFFFFF;
    border: 1px solid #F94A29;
    border-radius: 5px;
    color: #F94A29;
    margin: 0px 5px;
    padding: 3px 16px;
    transition: all 0.2s ease-in-out;
    position: relative;
    overflow: hidden;
}



.tempBtn button:hover {
    box-shadow: 1px 1px 2px 1px #dbdbdb;
    transform: scale(1.01);
    -webkit-transform: scale(1.01);
    -moz-transform: scale(1.01);
    -ms-transform: scale(1.01);
    -o-transform: scale(1.01);
}

@-webkit-keyframes sheen {
    0% {
        transform: skewY(-45deg) translateX(0);
    }

    100% {
        transform: skewY(-45deg) translateX(12.5em);
    }
}

@keyframes sheen {
    0% {
        transform: skewY(-45deg) translateX(0);
    }

    100% {
        transform: skewY(-45deg) translateX(12.5em);
    }
}

.requestInputForm label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #A0A0A0;
    display: block;
    margin-bottom: 7px;
}

.requestInputForm input {
    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 9px 20px;
    color: rgba(116, 118, 121, 1);
    width: 77%;
    /* margin-bottom: 15px; */
}

.addItem h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    /* Font Color */
    color: #303030;
}

.requiredLine {
    display: flex;
}

.requiredLine p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #A0A0A0;
    padding-right: 15px;
}

/* attach file */
.attachFile p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #A0A0A0;
}

.itemList {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 25px;
    /* height: calc(100vh - 210px);  */
    position: relative;
    height: 100%;
    margin-left: 20px;
}

.itemTable .table-responsive {
    height: 440px;
    overflow-y: auto;
}

.itemTable .table thead {
    top: 0px;
    position: sticky;
    background-color: white;
    z-index: 9;
}

.itemList h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #303030;
}

.tableBody {
    border-collapse: separate;
    border-spacing: 0 1em;
}

.tableBody thead th {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #747679;
    white-space: nowrap;

}

.tableBody tbody tr {
    vertical-align: middle;
    border: none !important;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.tableBody tbody td {
    background: #F6F7F8;
    border: none !important;
}

.tableBody tbody td:last-child {
    border-radius: 0 8px 8px 0;
    -webkit-border-radius: 0 8px 8px 0;
    -moz-border-radius: 0 8px 8px 0;
    -ms-border-radius: 0 8px 8px 0;
    -o-border-radius: 0 8px 8px 0;
    text-align: end;
}


.tableBody tbody td:first-child {
    border-radius: 8px 0px 0px 9px;
    -webkit-border-radius: 8px 0px 0px 9px;
    -moz-border-radius: 8px 0px 0px 9px;
    -ms-border-radius: 8px 0px 0px 9px;
    -o-border-radius: 8px 0px 0px 9px;
}

.itemList table tbody tr td:nth-child(1),
.itemList table tbody tr td:nth-child(2) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #747679;
}

.itemList table tbody tr td:nth-child(3) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #747679;
}

.tableInnerButton {
    display: flex;
    justify-content: end;
}

.tableInnerButton .mr-2 {
    margin-right: 5px;
}

/* .tableInnerButton button {
    background: transparent;
    border: 1px solid #F94A29;
    border-radius: 3px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    color: #F94A29;
    margin: 0px 3px;
    padding: 3px 6px;
} */

/* drag and drop file */
.dragDropBox {
    display: flex;
}
.drop-file-Upload{
    position: relative;
    cursor: none;
    pointer-events: none;
    width: 100%;
    height: 186px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFBFA;
    border: 2px dashed #FFA366;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;

}
.drop-file-input {
    position: relative;
    width: 100%;
    height: 186px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFBFA;
    border: 2px dashed #FFA366;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.drop-file-input input,.drop-file-Upload input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
    opacity: 0.6;
}

.drop-file-input__label {
    text-align: center;
    color: var(--txt-second-color);
    font-weight: 600;
    padding: 10px;
}

.drop-file-input__label img {
    width: 35px;
}

.drop-file-input__label p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #303030;
}

.drop-file-preview {
    margin-top: 30px;
}

.drop-file-preview p {
    font-weight: 500;
}

.drop-file-preview__title {
    margin-bottom: 20px;
}

.drop-file-preview__item {
    position: relative;
    display: flex;
    margin-bottom: 10px;
    background-color: var(--input-bg);
    padding: 15px;
    border-radius: 20px;
}

.drop-file-preview__item img {
    width: 50px;
    margin-right: 20px;
}

.drop-file-preview__item__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.drop-file-preview__item__del {
    background-color: var(--box-bg);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: var(--box-shadow);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
    opacity: 1;
}

/* optional button */
.optionalButton {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 20px;
    left: 0px;
    width: 100%;
    padding: 0px 22px;
    background-color: #fff;
    padding: 5px 25px;
    z-index: 999;
}

.optionalButton .required {
    display: flex;
    align-items: center;
}

.optionalButton .required p {
    margin-bottom: 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #A0A0A0;
}

.optionalButton .required button {
    background: #F94A29;
    border-radius: 2px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 6px 14px;
    border: none;
    margin-left: 10px;
    letter-spacing: 1px;
    /* position: absolute;
    bottom: 0px;
    right: 8px; */

}

.optionalButton .required button p {
    color: #FFFFFF;
    font-weight: 600;
    letter-spacing: 1px;
}

.mtAdd button {
    margin-top: 29px;
}

.fileUploadText td {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #747679 !important;
    text-align: left;
    white-space: nowrap;
}

.fileUploadText {
    background: #F6F7F8 !important;
}

.footerDashboard {
    margin-top: 20px;
    display: flex;
    justify-content: end;
}

.footerDashboard .backDashboard {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration: none;
    color: #F94A29;

    background: #F8F8F8;
    background: linear-gradient(to right, #f94c2969 50%, #F8F8F8 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
    border: 2px solid #F94A29;
    border-radius: 5px;
    width: 185px;
    padding: 10px 8px;
}

.footerDashboard .backDashboard:hover {
    background-position: left bottom;
    color: #fff;

}

/* .footerDashboard .backDashboard a {
    text-decoration: none;
    color: #F94A29;
} */

.footerDashboard .nextButton {

    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    width: 185px;
    padding: 10px 8px;
    text-decoration: none;
    background: linear-gradient(to right, #f94c2969 50%, #F94A29 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
}

.footerDashboard .nextButton:hover {
    background-position: left bottom;
    color: #fff;
}

.addItemList {
    background: #F94A29;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 11px 30px;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.addNote h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #303030;
}

.addNote textarea {
    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    padding: 11px 20px;
    color: rgba(116, 118, 121, 1);
    width: 100%;
    height: 250px;
    margin-bottom: 20px;
}

.mr-2 {
    margin-right: 5px;
}

.loadModal .modal-dialog {
    width: 547px !important;
}

.loadModal .requestInputForm input {
    width: 100% !important;
}

.saveBtn {
    display: block;
    margin-bottom: 5px;
    color: #F94A29 !important;
    font-size: 27px;
    font-weight: 700;
    letter-spacing: 1px;
}

.signatureCheckBox {
    position: relative;
}

.signatureCheckBox .tableCheckBox {
    margin-left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
}

.bottomIcon {}

.bottomIcon img {
    width: 16px;
    margin: 2px 5px;
}

.requestFileList {
    padding: 0px 10px;
}

.requestFileList .tableBody thead th {
    font-size: 13px !important;
}

.addEmployee {
    width: auto !important;
    background: #F94A29;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 5px 30px;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.employeeBox {
    padding: 35px 25px;
}

.employeeBox h1 {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #303030;
    margin-bottom: 20px;
}

.employeeTable .tableBody tbody td:last-child {
    text-align: left;
}

.employeeBtn {
    display: flex;
    justify-content: center;
}

.employeeBtn .back {
    width: 190px;
    padding: 9px 0px;
    border: 2px solid #f94a29;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #f94a29;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px #0000001a;
    margin-right: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.employeeBtn .save {
    width: 190px;
    background: rgb(249, 74, 41);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(249, 74, 41);
    border-radius: 5px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    padding: 1px 17px;
    height: 40px;
    margin: 0px 5px;
    position: relative;
    overflow: hidden;
    animation: 1s ease 0s 1 normal backwards running fadeInUp;
}

.addEmployeeBtn {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.templateUpdate {
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
}

.templateUpdate:before {
    content: "";
    width: 100%;
    height: 2px;
    background-color: #F94A29;
    position: absolute;
    display: block;
    margin-top: 14px;
    z-index: 9;
}

.templateUpdate span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 28px;
    text-align: center;
    color: #303030;
    background-color: #fff;
    position: relative;
    z-index: 9999;
}
.templateUpdateBtn{
    margin-top: 10px;
    display: flex;
    justify-content: center;
}
.templateUpdateBtn button {
    background: #f94a29;
    border-radius: 5px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    width: 185px;
    padding: 10px 8px;
    border: none;
    display: block;
    text-align: center;
}

/* responsive */
@media (-webkit-min-device-pixel-ratio: 1) and (max-width: 820px) {
    .createRequest {
        min-height: 100vh;
        padding-top: 80px !important;
        padding: 0px;
    }

    .createLeftBox {
        padding: 25px 10px;
        margin-left: 0px;
    }

    .templateLine {
        flex-wrap: wrap;
    }

    .templateButton button {
        font-size: 15px;
        line-height: 24px;
        padding: 3px 8px;
        margin-top: 8px;
        margin-bottom: 2px;
    }

    .templateButton {
        margin-bottom: 15px;
    }

    .templateButton {
        flex-wrap: wrap;
        justify-content: center;
    }


    .itemList {
        margin-top: 20px;
        margin-left: 0px;
    }

    .requestInputForm input {
        width: 100%;
        padding: 11px 11px;
    }

    .receiptList {
        margin: 30px 0px !important;
    }

    .addContactInput {
        height: auto !important;
    }

    .optionalButton {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: 0px 1px;
        bottom: 0px !important;
        margin-top: 10px;
    }

    .settingModalBody .modal-body {
        padding: 25px 22px;
    }

    .settingModalBody .modal-body {
        padding: 25px 22px;
    }

    .requestInput {
        margin-top: 10px;
    }

    .loadModal .modal-dialog {
        width: 96% !important;
    }

    .addItemList {
        margin-top: 15px;
        position: relative;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
    }
}

@media (-webkit-min-device-pixel-ratio: 1) and (max-width:420px) {
    .footerDashboard .nextButton {
        margin-top: 15px;
    }

}

@media (-webkit-min-device-pixel-ratio: 1) and (max-width:360px) {
    .optionalButton {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .optionalButton .required {
        margin-top: 5px;
        width: 100%;
        justify-content: space-between;
    }
}