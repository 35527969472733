.height-100 {
    height: 100vh
}
 .downloadTable thead tr th{
    text-align: left;
 }
.downloadTable tbody td{
    font-family: 'Inter';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 15px !important;
  color: #747679 !important;
}
.EmailCard {
    width: 400px;
    border: none;
    padding: 50px 0px;
    box-shadow: 0px 5px 20px 0px #d2dae3;
    z-index: 1;

}

.EmailCard h1 {
    color: #F94A29 !important;
    font-size: 27px;
    font-weight: 700;
    letter-spacing: 1px;
}

.EmailCard h6 {
    color: rgb(49, 40, 45);
    font-size: 20px;
    font-family: 'Inter';
    font-size: 22px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
}
.EmailCard h3 {
    color: rgb(49, 40, 45);
    font-size: 13px;
    font-family: 'Inter';
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
}

.EmailCard span {
    margin-top: 15px;
    color: #F94A29 !important;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    padding: 10px 28px;
    text-align: center;
    cursor: pointer;
    width: auto;
    display: inline-flex;
}

.inputs input {
    width: 120px;
    height: 40px
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0
}

.card-2 {
    background-color: #fff;
    padding: 10px;
    width: 350px;
    height: 100px;
    bottom: -50px;
    left: 20px;
    position: absolute;
    border-radius: 5px
}

.card-2 .content {
    margin-top: 50px
}

.card-2 .content a {
    color: red
}

.form-control:focus {
    box-shadow: none;
    border: 2px solid red
}

.validate {
    margin-top: 50px;
    padding: 8px 0px;
    border-radius: 20px;
    height: 40px;
    background-color: #F94A29 !important;
    color: #fff;
    width: 140px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border: none;
    cursor: pointer;
}

.emailItem {
    min-height: 100vh;
    padding-top: 3%;
}

.requestItemList {
    box-shadow: 0px 0px 10px 0px #0000001A;
    z-index: 1;
    padding: 40px 30px;
    background-color: #fff;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    height: 100%;
    overflow-y: auto;
}

.requestItemList h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #303030;
}

.checkList button {
    position: relative;
    width: 100%;
    background-color: #F6F7F8;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    color: #000;
    margin-bottom: 8px;
}


.disabledComplete {
    pointer-events: none;
    opacity: 0.5;
}

.checkList h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 30px;
    color: #303030;
    margin-bottom: 8px;
}

.checkList .tableBody thead th {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #747679;
    white-space: nowrap;
  }



.continueBtn {
    margin-top: 10px;
    text-align: center;
    background-color: #127acf;
    padding: 8px 25px;
    font-size: 18px;
    line-height: 26px;
    color: #fff;
    border: none;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    text-decoration: none;
}

.downloadSection {
    margin-top: 80px;
    height: calc(100vh - 80px);
    position: relative;
    display: flex;
    align-items: center;
}

.downloadUpload {
    box-shadow: 0px 0px 10px 0px #0000001A;
    z-index: 1;
    padding: 40px 30px;
    background-color: #fff;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    height: calc(100vh - 205px);
    position: relative;
    overflow-y: auto;
}

.downloadFile h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #303030;
}

.downloadFile h6 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #303030;
}

.downloadButton {
    margin-top: 40px !important;
  position: relative;
  display: flex;
  justify-content: end;
}

.modalHead h1 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: #5e5873;
}

.warningText h1 {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #E11105;
}

.partialModal {
    padding: 20px 30px;
}

.partialHeading h1 {
    font-family: "Inter";
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #303030;
    margin-bottom: 8px;
}

.submissionRules {
    padding: 10px;
    width: 45%;
    margin-top: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 1px 1px #22222238;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.partialModal .activeBox {
    background-color: #F94A29;
}

.partialModal .activeBox h1,
.partialModal .activeBox p {
    color: #fff !important;
}

.submissionBox {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.submissionRules h1 {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    margin-bottom: 10px;
}

.submissionRules p {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
}

.rightButton {
    text-align: right;
    display: block;
}

/* modal */

.modal-confirm {
    color: #636363;
    font-size: 14px;
}

.modal-confirm .modal-content {
    padding: 20px;
    border-radius: 5px;
    border: none;
}

.modal-confirm .modal-header {
    border-bottom: none;
    position: relative;
}

.modal-confirm h4 {
    text-align: center;
    font-size: 26px;
    margin: 30px 0 -15px;
}

.modal-confirm .form-control,
.modal-confirm .btn {
    min-height: 40px;
    border-radius: 3px;
}

.modal-confirm .close {
    position: absolute;
    top: -5px;
    right: -5px;
}

.modal-confirm .modal-footer {
    border: none;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
    justify-content: center !important;
}

.modal-confirm .modal-footer button {
    padding: 8px 30px;
}

.modal-confirm .icon-box {
    color: #fff;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -70px;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    z-index: 9;
    background: #82ce34;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.modal-confirm .icon-box svg {
    font-size: 58px;
    position: relative;
    top: 3px;
}

.modal-confirm.modal-dialog {
    margin-top: 80px;
}

.modal-confirm .btn {
    color: #fff;
    border-radius: 4px;
    background: #F94A29;
    text-decoration: none;
    transition: all 0.4s;
    line-height: normal;
    border: none;
}

.modal-confirm .btn:hover,
.modal-confirm .btn:focus {
    background: #F94A29;
    outline: none;
}

.trigger-btn {
    display: inline-block;
    margin: 100px auto;
}

.modal-confirm .icon-box {
    background-color: #F94A29;
}

.toastPosition .bottom-0 {
    bottom: 100px !important;
}

.modal-confirm .icon-box {
    background-color: #F94A29;
}

.modal-confirm .downloadSuccess {
    background-color: #14A800;
}

.downloadModal .btn {
    background-color: #14A800;
    padding: 8px 25px;
}

.itemCheckBox .tableCheckBox {
    margin-left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
}

.dropBoxDisable {
    pointer-events: none;
}
.deActiveItem,.activeItem{
    cursor: pointer;
}
.deActiveItem td,.activeItem td{
    color: #747679 !important;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
}
.activeItem .mark {
    color: #F94A29 !important;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
}

.dropdownOff {
    pointer-events: none;
}

.imageDescription {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 0px #00000026;
    padding: 18px 15px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
   margin-top: 20px;
}

.imageDescription h2 {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
}

@media (-webkit-min-device-pixel-ratio: 1) and (max-width:778px) {
    .position-relative {
        position: relative;
        width: 100%;
    }
    .downloadUpload{
        margin-right: 0px;
    }
    .requestItemList{
        margin-top: 25px;
    }
    .EmailCard {
        width: 100%;
    }
    .downloadButton{
        justify-content: center;
        margin-bottom: 30px;
    }
    .mr-2 {
        margin-right: 0px;
    }
    .downloadSection {
        margin-top: 80px;
        height: auto;
        position: relative;
        display: flex;
        align-items: center;
    }
}