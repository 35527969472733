.congratulationPage{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.accountReady{
    text-align: center;
   max-width: 650px !important;
    box-shadow: 0px 0px 15px 2px #00000026;
    padding: 50px 40px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.accountReady h1{
    font-family: "Inter";
  font-size: 48px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: center;
  color: #f85b3b;
  margin-bottom: 15px;
}
.accountReady p{
    font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.04em;
  color: #303030;
  text-decoration: none;
  display: block;
}
.accountReady svg{
    color: #F94A29;
    font-size: 80px;
    margin-bottom: 20px;
}
.accountReady button{
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.15) 2px 6px 20px 2px;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgb(249, 74, 41);
  padding: 14px 30px;
  border: 1px solid rgb(249, 74, 41);
  text-decoration: none;
}