.dashboardNavbar .header {
    padding-left: 35px;
    padding-right: 50px;
    box-shadow: 0px 4px 10px 0px #0000000F;
}

.dashboardNavbar .freeNotification {
    position: absolute;
    top: 51px;
    right: 5px;
    background-color: #ffffff !important;
    border: 1px solid #F94A29;
    z-index: 999999999;
    padding: 4px 15px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.dashboardNavbar .freeNotification p{
    margin-bottom: 0px;
    color: #F94A29 !important;
    font-size: 13px;
    font-weight: 600;
    font-family: "Inter";
}

.header {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.logo {
    color: #F94A29 !important;
    font-size: 27px;
    font-weight: 700;
    letter-spacing: 1px;
}

.headerItem a {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    color: #303030;
}

.headerItem a:hover {
    color: #F94A29 !important;
}

.activeNavbar {
    color: #F94A29 !important;
}

.headerBtn button {
    margin: 0 5px;
}

.ml-auto {
    margin-left: auto;
}

.accountBtn {
    background: #FFFFFF !important;
    border: 1px solid #F94A29 !important;
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    text-align: center !important;
    color: #F94A29 !important;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
    margin: 0px 4px;
    padding: 6px 21px 6px 12px !important;
}

.logoutBtn {
    background: #FFFFFF !important;
    border: 1px solid #F94A29 !important;
    border-radius: 3px !important;
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    text-align: center !important;
    color: #F94A29 !important;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    margin: 0px 4px;
    padding: 6px 26px 6px 15px !important;
    position: relative;
}

.logoutBtn img {
    position: absolute;
    right: 10px;
    top: 13px;
}

.notifyMessage{
    position: fixed;
    top: 25px;
    right: 25px;
    padding: 12px 15px;
    background-color: #fff;
    z-index: 9999;
    box-shadow: 0 1px 10px 0 rgba(0,0,0,.1),0 2px 15px 0 rgba(0,0,0,.05);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.notifyMessage a{
    margin-bottom: 0px;
    color: #f94a29;
    font-weight: 500;
    font-size: 17px;
    font-family: "Inter";
    text-decoration: none;
}
.msfModal h1{
    font-family: "Inter";
    font-size: 33px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    color: #303030;
    margin-bottom: 15px;
}
.msfModal a{
    margin-top: 10px;
    display: inline-block;
    background: rgb(249, 74, 41);
    box-shadow: rgba(0, 0, 0, 0.15) 2px 6px 20px 2px;
    border-radius: 5px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: rgb(255, 255, 255);
    padding: 14px 25px;
    border: 1px solid rgb(249, 74, 41);
    text-decoration: none;
    margin-right: 8px;
}
@media (-webkit-min-device-pixel-ratio: 1) and (max-width:778px) {
    .headerItem {
        width: 100%;
        position: relative;
    }

    .logoutBtn {
        width: 100%;
        margin: 4px 0px;
    }
}