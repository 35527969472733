.whyBannerSection {
  display: flex;
  align-items: center;
  padding-top: 165px;
  padding-bottom: 145px;
  background: linear-gradient(to left, #f94a29 30%, rgb(255, 255, 255) 25%);
}

.whyBannerContent h1 {
  font-family: "Inter";
  font-size: 45px;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
}

.whyBannerContent p {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
}

.emailHeading h1 {
  font-family: "Inter";
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: center;
  color: #303030;
  margin-top: 60px;
  margin-bottom: 130px;
}

.emailBox .emailNumber {
  width: 38px;
  height: 38px;
  background-color: #f94a29;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.emailBox h1 {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0em;
  margin: 15px 0px;
  text-align: left;
  color: #303030;
}

.emailBox p {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
}

.emailCard {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 65px;
}

/* faq */
.faqSection {
  margin-top: 15px;
  margin-bottom: 100px;
}

.faqHeading h1 {
  font-family: "Inter";
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: center;
  color: #303030;
  margin-bottom: 65px;
}

.faqBox .accordion-header .accordion-button {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
  padding: 27px 21px !important;
}

.faqBox .accordion-item {
  border-top: 1px solid #adadad;
  border-bottom: 1px solid #adadad;
  border-left: none !important;
  border-right: none !important;
  border-radius: none !important;
  -webkit-border-radius: none !important;
  -moz-border-radius: none !important;
  -ms-border-radius: none !important;
  -o-border-radius: none !important;
}

.faqBox .accordion-item:first-of-type {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.faqBox .accordion-button:not(.collapsed) {
  background-color: #f8f8f8e3 !important;
}

.whyBannerImage {
  position: relative;
}

.whyBannerImage img {
  width: 100%;
}

.whyBannerImage .youTube {
  width: 90px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

@media (-webkit-min-device-pixel-ratio: 1) and (max-width: 780px) {
  .emailCard {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 65px;
  }

  .whyBannerImage img {
    width: 100%;
  }
}