.header {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.logo {
    color: #F94A29 !important;
    font-size: 27px;
    font-weight: 700;
    letter-spacing: 1px;
}
.loginBtn a{
    text-decoration: none;
    color: #F94A29;
}
.loginBtn a:hover{
    text-decoration: none;
    color: #F94A29;
}
.headerItem a {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    color: #303030;
}

.headerBtn button {
    margin: 0 5px;
}
.registerBtn a{
    text-decoration: none;
    color: #fff;
}
.registerBtn a:hover{
    text-decoration: none;
    color: #fff;
}
@media (-webkit-min-device-pixel-ratio: 1) and (max-width:778px) {
    .headerItem{
        text-align: center;
    }
    .headerBtn{
        justify-content: center;
    }
}