.billInput {
  margin-left: 15px;
  margin-right: 20px;
}

.paymentMethod {
  max-width: 520px;
  padding: 45px 65px 45px 54px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-shadow: 0px 0px 10px 0px #0000001a;
  margin-right: 15px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.paymentHeading {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
  margin-top: 30px;
}

.billInput .CardNumberField-input-wrapper,
.StripeElement {
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding: 0px 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: rgba(116, 118, 121, 0.8);
  position: relative;
  display: grid;
  align-items: center;
}

.billInput .dropdown {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: rgb(54, 54, 55);
  height: 40px;
  width: 100%;
  text-align: left;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.billInput .dropdown button {
  width: 100%;
  background-color: #fff;
  border: 1px solid #D2D2D2;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: rgb(54, 54, 55);
  text-align: left;
  height: 40px;
  padding: 0px 20px;
}

.billInput .dropdown button svg {
  position: absolute;
  right: 10px;
}

.billInput .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0em solid;
  border-right: 0em solid transparent;
  border-bottom: 0;
  border-left: 0em solid transparent;
}

.billInput .dropdown-menu {
  width: 100%;
  height: 350px;
  overflow-y: auto;
}

.billInput .btn:first-child:active {
  background-color: #ffffff !important;
  color: rgb(54, 54, 55) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #ffffff !important;
  color: rgb(54, 54, 55) !important;
}

@media (max-width: 778px) {
  .paymentMethod {
    padding: 45px 25px 45px 25px;
  }
}