#notfound {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notfound {
    max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center
}

.notfound .notfound-404 {
    position: relative;
}

.notfound .notfound-404 h1 {
    font-family: "Inter", sans-serif;
    font-size: 252px;
    font-weight: 900;
    margin: 0;
    color: rgb(249, 74, 41);
    text-transform: uppercase;
    letter-spacing: -40px;
    line-height: 210px;
    margin-left: -45px;
}

.notfound .notfound-404 h1>span {
    text-shadow: -8px 0 0 #fff
}

.notfound .notfound-404 h3 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #303030;
    margin-bottom: 0px;
}

.notfound h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #303030;
    margin-bottom: 5px;
}

@media only screen and (max-width:767px) {
    .notfound .notfound-404 {
        height: 200px
    }

    .notfound .notfound-404 h1 {
        font-size: 200px
    }
}

@media only screen and (max-width:480px) {
    .notfound .notfound-404 {
        height: 162px
    }

    .notfound .notfound-404 h1 {
        font-size: 162px;
        height: 150px;
        line-height: 162px
    }

    .notfound h2 {
        font-size: 16px
    }
}