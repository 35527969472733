.securitySections {
  background: #ffffff;
  min-height: 100vh;
  width: 100%;
  padding: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.securityBox {
  max-width: 650px;
  min-height: 330px;
  background: #ffffff;
  box-shadow: 0px 0px 40px 1px #0000001a;
  padding: 25px 70px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}





.deleteAccount,
.userPasswordCheck {
  padding: 30px 20px;
}

.deleteAccount h1,
.userPasswordCheck h1 {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #303030;
}
.userPasswordCheck span{
  margin-top: 5px;
  color: #F94A29 !important;
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  padding: 10px 28px;
  text-align: center;
  cursor: pointer;
  width: auto;
  display: inline-flex;
}

.deleteAccountBtn {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.deleteAccountBtn button {
  background: rgb(249, 74, 41);
  box-shadow: rgba(0, 0, 0, 0.15) 2px 6px 20px 2px;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgb(255, 255, 255);
  padding: 14px 30px;
  border: 1px solid rgb(249, 74, 41);
}

.deleteAccount .warning {
  font-size: 12px;
  color: #F94A29 !important;
  font-weight: 600;
}

.userPasswordCheck .verificationCode .vi__wrapper {
  margin-top: 15px;
}

@media (max-width:778px) {
  .securitySections {
    padding: 90px 0px;
  }

  .securityBox {
    padding: 25px 20px;
  }
}