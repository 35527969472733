.myFilesList .table-responsive {
  height: calc(79vh - 131px);
  position: relative;
}
.myFilesList .table-responsive .tableBody thead tr th{
  cursor: pointer;
}

.filesBox {
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 15px;
  min-height: calc(100vh - 150px);
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.deleteBox {
  padding: 60px 0px;
}

.deleteBox h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 30px;
  text-align: center;
  color: #303030;
}

.deleteBox p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: #f94c29d8;
  text-align: center;
}

.deleteBox button {
  width: 140px;
  padding: 9px 0px;
  font-family: "Inter";
  border: none;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  box-shadow: 0px 0px 10px 0px #0000001a;
  margin-right: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.deleteBox .no {
  color: #ffffff;
  background: #007bff;
}

.deleteBox .yes {
  color: #ffffff;
  background: #f94a29;
}

.myFilesList table tbody tr td {
  text-wrap: nowrap !important;
}

.filesHeading {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.filesHeading h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 19px;
  color: #303030;
  margin-right: 20px;
  margin-bottom: 0px;
}

.filesHeading .vault {
  background: rgb(249, 74, 41);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(249, 74, 41);
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding: 1px 17px;
  height: 40px;
  margin: 0px 5px;
  position: relative;
}

.filesHeading .vaultBlank {
  background: #a8aeb9;
  color: rgb(255, 255, 255);
  border: 1px solid #a8aeb9;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding: 1px 17px;
  height: 40px;
  margin: 0px 5px;
  position: relative;
}
.filesHeading .tooltip-top:before {
  content: attr(data-tooltip);
  padding: 8px 16px;
  color: #F94A29;
  font-weight: 600;
  white-space: pre;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 12px;
  z-index: 998;
}

.importFiles{
padding: 20px;
}
.importFiles h1{
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  color: #303030;
  text-align: center;
}
.filesBtn{
  text-align: center;
}
.filesBtn button{
  text-align: center;
  background: rgb(249, 74, 41);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(249, 74, 41);
  border-radius: 5px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding: 1px 17px;
  height: 40px;
  margin: 0px 5px;
  position: relative;
  overflow: hidden;
  animation: 1s ease 0s 1 normal backwards running fadeInUp;
}
/* .filesHeading .vaultBlank svg{
  color: #f94a29;
} */