.imageContent {
  display: flex;
  align-items: center;
  padding: 77px 0px;
}
.imageWithContent{
  display: flex;
  align-items: center;
  padding-top: 77px;
  padding-bottom: 77px;
  padding-right: 67px;
  padding-left: 50px;
  background: #fff;
}
.contentImage {
  display: flex;
  align-items: center;
  padding: 77px 0px;
  background: #ffefec;
}
.contentWithImg {
  display: flex;
  align-items: center;
  padding-top: 77px;
  padding-bottom: 77px;
  background: #ffefec;
}
.rightContent {
  max-width: 550px;
  position: relative;
}
.rightContent h1 {
  font-family: "Inter";
  font-size: 48px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
  margin-bottom: 15px;
}
.rightContent p {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
}
.rightContent a{
  margin-top: 10px;
  display: inline-block;
  background: rgb(249, 74, 41);
  box-shadow: rgba(0, 0, 0, 0.15) 2px 6px 20px 2px;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgb(255, 255, 255);
  padding: 14px 15px;
  border: 1px solid rgb(249, 74, 41);
  text-decoration: none;
  margin-right: 8px;
}
.rightContent .googleForm{
  /* position: absolute;
  left: 50%;
  transform: translate(-50%,0);
  -webkit-transform: translate(-50%,0);
  -moz-transform: translate(-50%,0);
  -ms-transform: translate(-50%,0);
  -o-transform: translate(-50%,0); */
}
.contentWhyImg{
  padding-top: 77px;
  padding-bottom: 77px;
  background: #ffefec;
}
.imageWhyContent{
  padding-top: 77px;
  padding-bottom: 77px;
  background: #ffefec;
}


.contentCapital h1{
  font-family: 'Inter';
font-size: 18px;
font-weight: 600;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color: #303030;
margin-top: 15px;
margin-bottom: 6px;
}
.lineBox{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 778px) {
  .imageContent .lineBox, .contentImage .lineBox{
    flex-wrap: wrap;
  }
  .leftImage img{
    width: 100%;
  }
  .rightContent {
    padding: 20px;
  }
  .rightContent h1{
    font-size: 38px;
  }
  .contentWhyImg,.imageWhyContent,.contentWithImg,.imageWithContent{
    flex-wrap: wrap;
    padding: 20px;
  }
}