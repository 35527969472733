.paymentInfo {
  background: #ffffff;
  padding: 105px 0px;
}

.paymentHeading h1 {
  font-family: "Inter";
  font-size: 45px;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: center;
  color: #303030;
}

.paymentHeading p {
  font-family: "Inter";
  font-size: 22px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #303030;
}

.paymentFeature {
  text-align: center;
  margin-bottom: 150px;
}

.paymentFeature h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 30px;
  color: #303030;
}

.paymentFeature p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  color: #f94a29;
  cursor: pointer;
  display: inline;
}

.paymentFeature h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 30px;
  color: #303030;
  margin-top: 30px;
}

.coreFeature .table-responsive {
  padding: 25px 2px;
}


.coreFeature table tbody tr td {
  border: 1px solid rgba(249, 74, 41, 0.3);
}

.coreFeature table thead tr th:nth-child(1) {
  width: 270px;
}

.coreFeature table thead tr th:nth-child(2),
.coreFeature table thead tr th:nth-child(3),
.coreFeature table thead tr th:nth-child(4),
.coreFeature table thead tr th:nth-child(5) {
  border: 1px solid rgba(249, 74, 41, 0.3);
  padding-top: 48px;
  padding-bottom: 27px;
  width: 200px;
}

.coreFeature table thead tr th:nth-child(3) {
  border: 3px solid #F94A29;
  background: #FFEFEC;
  padding-top: 48px;
  padding-bottom: 27px;
  /* position: sticky; */
}

.bottomBorder {
  border-bottom: 3px solid #F94A29 !important;
}

.coreFeature table tbody tr td:nth-child(3) {
  border-left: 3px solid #F94A29;
  border-right: 3px solid #F94A29;
  background: #FFEFEC;
}

.coreFeature table tbody tr td:nth-child(1) .leftHeading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: left;
  color: #303030;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  height: 45px;
}

.coreFeature table thead tr th:nth-child(1) img,
.coreFeature table tbody tr td:nth-child(1) img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.coreFeature table thead tr th {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #303030;
  height: 80px;
}

.coreFeature table tbody tr td {
  vertical-align: middle;
  text-align: center;
  color: #303030;
  font-size: 19px;
  font-weight: 700;
}

.coreFeature table tbody tr td p {
  /* width: 70%; */
  margin-bottom: 0px;
}

.coreFeature table tbody tr td svg {
  color: #F94A29;
  font-size: 24px;
}

.limitedText {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  color: #303030;
  width: 100% !important;
}

.pricingContent p {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
}

.packageName h1 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 27px;
  text-align: center;
  color: #303030;
}

.packagePrice h1 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  color: #F94A29;
  text-align: center;
  margin-top: 18px;
}

.packagePrice h1 .icon {
  color: #F94A29;
  font-weight: 500;
  /* padding-right: 6px; */
  font-size: 24px;
}

.packagePrice h1 .currency {
  color: #303030;
  font-weight: 500;
  padding-left: 9px;
  font-size: 22px;
}

.packagePrice p {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 26px;
  color: #303030;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 30px;
  white-space: nowrap;
}

.freePrice {
  padding: 52px 0px;
  text-align: center;
  background-color: #FFEFEC;
}

.freePrice h1 {
  font-family: "Inter";
  font-size: 42px;
  font-weight: 600;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: center;
  color: #303030;
}

.freePrice p {
  margin-top: 28px;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: #303030;
}

.freePrice button {
  margin-top: 35px;
}

.previousPrice::before {
  height: 3px;
  width: 66px;
  background-color: #A8AEB9;
  position: absolute;
  left: 0px;
  display: inline-block;
  top: 19px;
  content: "";
}

.previousPrice {
  margin-right: 12px;
  color: #A8AEB9;
  position: relative;
  font-size: 33px;
  font-weight: 600;
}

.previousPrice .icon {
  position: relative;
  color: #A8AEB9 !important;
  font-weight: 500;
  font-size: 19px !important;
}

.recommendTableLine p {
  top: -73px;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  position: absolute;
  background: #F94A29;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #FFEFEC;
  padding: 5px 14px;
  box-shadow: 2px 6px 20px 2px #00000026;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.packageSelect {
  text-align: center;
  display: flex;
  justify-content: center;
}

.packageSelect .googleForm {
  background: rgb(249, 74, 41);
  border: 1px solid rgb(249, 74, 41);
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: rgb(255, 255, 255);
  width: 140px;
  height: 44px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.upgradePlanBox {
  padding: 30px 30px 50px 30px;
}

.upgradePlanBox .priceTab {
  margin-bottom: 50px !important;
}

.upgradePlanBox .table-responsive {
  padding: 25px 2px;
}

.upgradePlanBox table thead tr th:nth-child(1),
.upgradePlanBox table thead tr th:nth-child(2),
.upgradePlanBox table thead tr th:nth-child(3) {
  border: 1px solid rgba(249, 74, 41, 0.3);
  padding-top: 48px;
  padding-bottom: 27px;
  width: 200px;
  position: relative;
}

.upgradePlanBox table thead tr th:nth-child(2) {
  border: 3px solid #F94A29;
}

.recommendText p {
  top: -16px;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  position: absolute;
  background: #F94A29;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #FFEFEC;
  padding: 5px 14px;
  box-shadow: 2px 6px 20px 2px #00000026;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

@media (max-width:778px) {
  .packagePrice p {
    font-size: 11px;
  }

  .packagePrice h1 {
    font-size: 25px;
  }

  .previousPrice {
    font-size: 23px;
    margin-right: 1px;
  }

  .previousPrice::before {
    height: 3px;
    width: 40px;
    top: 13px;
  }
}