.deleteBoxModal {
    padding: 30px 25px;
}

.deleteBoxModal h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 37px;
    color: #303030;
    text-align: center;
}

.deleteBoxModal p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #f94c29c9;
    margin-bottom: 5px;
}