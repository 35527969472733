.privacyPage {
    margin-top: 120px;
    margin-bottom: 80px;
}

.privacyHeading h1 {
    font-family: "Inter";
    font-size: 45px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    color: #303030;
    margin-bottom: 0px;
}

.privacyHeading h2 {
    font-family: "Inter";
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
    color: #303030;
    margin-bottom: 15px;
}

.privacyHeading p {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #303030;
    margin-bottom: 0px;
}

.privacyContent h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 27px;
    color: #f94a29;
    margin-bottom: 17px;
    margin-top: 49px;
}

.privacyContent p {
    font-family: "Inter";
    font-size: 19px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0e0e;
    margin-bottom: 0px;
}

.privacyContent h6 {
    font-family: "Inter";
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0e0e;
    margin-bottom: 0px;
    text-transform: uppercase;
}
.privacyContent a {
    font-family: "Inter";
    font-size: 19px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #f94a29;
    margin-left: 5px;
    margin-bottom: 0px;
    text-decoration: none;
}

.privacyContent ul {
    margin-top: 20px;
}

.privacyContent ul li {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #303030;
}