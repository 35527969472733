/* banner */
.securitySection {
    display: flex;
    align-items: center;
    padding-top: 160px;
    padding-bottom: 100px;
}

.securityBannerContent h2 {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #303030;
}

.securityBannerContent h1 {
    font-family: "Inter";
    font-size: 45px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;
    color: #303030;
}

.securityBannerContent p {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #303030;
}

/* industry */
.industry {
    padding: 70px 0px;
}

.industryCard {
    padding: 95px 0px;
    background: #FFEFEC;
}

.industryHeading {
    text-align: center;
}

.industryCardHeading h1 {
    font-family: 'Inter';
    color: #303030;
    margin-bottom: 90px;
    font-size: 32px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;

}

.industryHeading h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 30px;
    text-align: center;
    color: #303030;
}

.industryHeading p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #303030;
}

.industryHeading h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #303030;
}

.industryHeading h5 a {
    color: #f94a29;
    text-decoration: none;
}

.industryBtn {
    margin-top: 25px;
    margin-bottom: 90px;
}

.cardBlog {
    display: flex;
    justify-content: space-between;
}

/* keyFeature */
.keyFeatureSection {
    padding-top: 110px;
    background-color: #fff;
}

.keyFeature {
    display: flex;
    background-color: #fff;
}

.keyFeatureHeading h1 {
    font-family: "Inter";
    font-size: 32px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: center;
    color: #303030;
    margin-bottom: 40px;
}

.keyFeature .featureBox:nth-child(1),
.keyFeature .featureBox:nth-child(3) {
    background: #ffefec;
}

.keyFeature .featureBox {
    padding: 40px 60px;
}

.keyFeature .featureBox h1 {
    font-family: "Inter";
    font-size: 26px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #303030;
}

.keyFeature .featureBox p {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #303030;
}

@media screen and (max-width: 778px) {
    .keyFeature {
        display: flex;
        flex-wrap: wrap;
    }

    .securityBannerContent h1 {
        font-size: 38px;
    }

    .keyFeature .featureBox {
        padding: 40px 20px;
    }

    .securityBannerImage img {
        width: 100%;
    }

    .cardBlog {
        flex-wrap: wrap;
        justify-content: center;
    }
}