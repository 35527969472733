@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600;700;800&display=swap');
body {
  margin: 0;
  /* font-family: 'Inter', sans-serif; */
}
button:focus:not(:focus-visible) {
  outline: 0;
}
.accordion-button:focus {
  z-index: 3;
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}