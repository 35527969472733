.recoveryBox {
  min-width: 650px;
  min-height: 330px;
  background: #ffffff;
  box-shadow: 0px 0px 40px 1px #0000001a;
  padding: 25px 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.recoveryBtn button {
  margin-top: 50px;
  background: #f94a29;
  box-shadow: 2px 6px 20px 2px #00000026;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding: 16px 100px;
  border: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.extraOption {
  margin-top: 18px;
}

.extraOption button {
  font-family: "Inter";
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #f94a29;
  border: none;
  background-color: transparent;
}

.anotherOption {
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.anotherOption a,
.anotherOption button {
  font-family: "Inter";
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #f94a29;
  background-color: transparent;
  border: none;
}

.verificationCode {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.verificationCode .vi__wrapper {
  margin-top: 30px;
}

.verificationCode .character {
  border: 1px solid #d2d2d2;
  font-size: 20px;
  font-weight: 600;
  border-radius: 8px;
  width: 50px;
  color: #f94a29;
  background-color: #22222200;
}

.verificationCode h1 {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #303030;
}

.verificationCode span {
  margin-top: 15px;
  color: #F94A29 !important;
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  padding: 10px 28px;
  text-align: center;
  cursor: pointer;
  width: auto;
  display: inline-flex;
}

.securityQus {
  width: 100%;
}

.securityQus h1 {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 40px;
  letter-spacing: 0em;
  text-align: center;
  color: #303030;
}

.passwordCheck h1 {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 40px;
  letter-spacing: 0em;
  text-align: center;
  color: #303030;
}

.textColor {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #A0A0A0;

}