.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
}

.visible {
  opacity: 1;
}

.disablePag {
  pointer-events: none;
}

.rightSideTable .tableBody {
  border-collapse: separate;
  border-spacing: 0 0.5em;
}

.rightSideTable .tableBody thead {
  top: 0px;
  position: sticky;
  background-color: white;
  z-index: 9;
}

.tableData .dashboardSerial {
  margin-top: 5px;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 12px 13px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  flex-wrap: wrap;
  box-shadow: 0px 0px 10px 0px #0000001a;
}

.tableData {
  min-height: calc(100vh - 65px);
  position: relative;
  padding-top: 65px;
  padding-left: 50px;
  padding-right: 50px;
}

.dashboardItem label {
  width: 15px;
}

.dashboardItem td {
  text-align: center;
  white-space: nowrap;
}

.dashboardItem .process {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* checkbox */
.contactCheck {
  display: flex;
  align-items: center;
}

.checkLine {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 44px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkLine input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  left: 10px;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #a8aeb9;
}

.checkLine input:checked ~ .checkmark {
  background-color: #f94a29;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkLine input:checked ~ .checkmark:after {
  display: block;
}

.checkLine .checkmark:after {
  left: 6px;
  top: 3px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-width: medium;
  border-left-width: medium;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.contactNameList {
  display: block;
}

.contactNameList .contactName {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #747679;
  margin-bottom: 0px;
  text-decoration: none;
  display: block;
}

.contactNameList .contactEmail {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #747679;
  text-decoration: none;
  display: block;
}

.dashBtn img {
  margin-right: 8px;
}

/* left side */
.contactList {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 15px;
  margin-right: 15px;
  height: calc(100vh - 180px);
}

.contactList h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #303030;
}

.contactList .contact {
  height: calc(100vh - 300px);
  overflow-y: auto;
}

.page-link {
  background-color: transparent !important;
  border: none !important;
  padding: 0px !important;
  margin: 0px 4px !important;
}

.paginationNumber {
  background: #ffffff !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15) !important;
  text-decoration: underline;
  color: #303030 !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.05em;
  padding: 2px 10px;
  height: 100%;
  display: block;
}

.show-entries {
  display: flex;
  justify-content: end;
  align-items: center;
}

.hint-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  white-space: nowrap;
  text-align: right;

  color: #303030;
  margin-right: 10px;
}

.dropdownNumber button,
.dropdownNumber .btn.show,
.dropdownNumber .btn:hover {
  width: 55px !important;
  height: 26px !important;
  background-color: transparent;
  color: #303030;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #303030;
  box-shadow: 0px 0px 10px 0px #00000026;
  border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  -ms-border-radius: 2px !important;
  -o-border-radius: 2px !important;
}

.dropdownNumber .dropdown-menu.show {
  min-width: 56px;
  background: rgb(255, 255, 255);
}

.rightSideTable {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 15px;
  min-height: calc(100vh - 180px);
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.rightSideTable .table-responsive {
  height: calc(100vh - 323px);
  overflow-y: auto;
}

.searchName {
  position: relative;
  display: flex;
  align-items: center;
  color: #747679;
}

.searchName svg {
  position: absolute;
  left: 12px;
}

.dashboardInput svg {
  font-size: 12px;
  margin-top: 3px;
}

.dashboardInput input {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #747679;
  padding: 10px 10px 10px 28px;
  border: 1px solid #dcdcdcdc;
}

.searchName input {
  padding-left: 27px !important;
}

.tableSearch {
  margin-right: 10px;
}

.tableSearch input {
  width: 300px;
}

/* top content */
.tableTopContent {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
}

.tableTopContent .topBoxHeading {
  width: 150px;
}

.topBoxHeading h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #303030;
}

.topButtonContent {
  display: flex;
}

.topButtonContent button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #303030;
  padding: 8px 6px !important;
  white-space: nowrap;
  margin: 0px 3px;
}

.activeFilter {
  background: rgba(168, 174, 185, 0.2) !important;
  font-weight: 500 !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.tableLine {
  margin-top: 15px;
}

.tableLine .table {
  border: 2px solid darkcyan;
  border-collapse: separate;
  padding: 20px;
}

.tableLine thead tr th {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #747679;
  padding: 24px 7px;
  white-space: nowrap;
}

.tableLine thead tr th svg {
  color: #747679;
  font-size: 10px;
}

.tableLine tbody tr {
  border: none !important;
}

.tableLine tbody tr:nth-child(odd) {
  background-color: #f6f7f8 !important;
}

.dashboardTable thead tr th:not(:first-child) {
  cursor: pointer;
  text-align: center;
}

.dashboardTable tbody tr td {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #747679;
  height: 56px;
}

.dashboardTable tbody tr td p {
  margin-bottom: 0px;
}

.download button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #f94a29;
  border: 1px solid #f94a29;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  background-color: transparent;
}

.download .editBtn {
  width: 55px;
  background: #f94a29;
  color: #fff;
  margin-right: 10px;
}

.download .reminderBtn {
  width: 85px;
  background: #f94a29;
  color: #fff;
  margin-left: 10px;
}

/* checkbox */
/* .tableCheckBox {
    display: flex;
    align-items: center;
} */

.tableCheckBox {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 5px;
  margin-bottom: 12px;
}

.tableCheckBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.tableCheckBox .checkmark {
  position: absolute;
  top: 0;
  left: 0px;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #a8aeb9;
}

.tableCheckBox input:checked ~ .checkmark {
  background-color: #f94a29;
}

.tableCheckBox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.tableCheckBox input:checked ~ .checkmark:after {
  display: block;
}

.tableCheckBox .checkmark:after {
  left: 4px;
  top: 1px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-width: medium;
  border-left-width: medium;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.paginationFooter {
  position: absolute;
  bottom: 10px;
  left: 0px;
  width: 100%;
  padding: 0px 20px;
}

.activeBtn {
  background: #a8aeb9 !important;
  border-radius: 5px;
  border: 1px solid #a8aeb9 !important;
}

.CircularProgressbar-text {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 700 !important;
}

.chooseItem .removeBtn button {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border: none;
}

.chooseItem .removeBtn {
  background-color: transparent;
  border: none;
}

.page-item button {
  background-color: transparent;
  border: none;
}

.downloadSuccess {
  background-color: #fff;
}

.itemProfile {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}

.infoTop {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 100%;
  padding: 10px 12px;
}

.itemProfile .infoTop {
  width: 32%;
  position: relative;
}

.itemProfile .requestInput {
  width: 48%;
  position: relative;
}

.infoTop .btn-download,
.infoTop .btn-download:hover {
  position: absolute;
  right: 2px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  text-align: center;
  color: #f94a29;
}

.infoTop h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 0px;
  color: #303030;
}

.infoTop p {
  margin-bottom: 0px;
}

.infoLink {
  cursor: pointer;
  position: relative;
}

.infoLink svg {
  color: #fac529;
  font-size: 16px;
  margin-right: 5px;
}

.mr-3 {
  margin-right: 10px;
}

.modalItemTable td {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #747679 !important;
  white-space: nowrap;
}

.modalItemTable td:last-child {
  text-align: center !important;
}

/* tooltip */

.tooltip-top {
  position: relative;
  outline: none;
}

.tooltip-top:before {
  content: attr(data-tooltip);
  padding: 8px 16px;
  color: #555;
  white-space: pre;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  z-index: 998;
}

.tooltip-top:after {
  content: "";
  width: 10px;
  height: 10px;
  border: 1px solid transparent;
  border-left-color: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.tooltip-top:before,
.tooltip-top:after {
  position: absolute;
  cursor: default;
  opacity: 0;
  background-color: #fff;
  pointer-events: none;
  transition: all ease-out 0.15s;
  box-shadow: none;
}

.tooltip-top:hover:before,
.tooltip-top:hover:after {
  opacity: 1;
  margin: 0;
}

.tooltip-top:before {
  bottom: calc(100% + 8px + 4px);
  transform: translateX(-50%);
}

.tooltip-top:after {
  bottom: calc(100% + 3px + 4px);
  transform: translateX(-50%) rotate(-45deg);
}

.tooltip-top:before,
.tooltip-top:after {
  left: 50%;
  margin-bottom: -4px;
}

.spinLoader {
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: #ffffff;
  top: 0;
  left: 0;
  z-index: 9999;
}
.smallSpinLoader {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: #ffffff69;
  z-index: 9999;
}

.deleteBtn {
  display: flex;
  justify-content: center;
}

.deleteBtn button {
  width: 40%;
  min-height: 50px;
}

.deleteBtn .noDelete {
  display: block;
  background-color: #0984e3;
  color: #f6f7f8;
  text-align: center;
  border: 1px solid #0984e3;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin: 2px 10px !important;
  transform: translate(0, 0);
  font-size: 17px;
  font-weight: 600;
}

.deleteBtn .yesDelete {
  display: block;
  background-color: #d63031;
  color: #f6f7f8;
  text-align: center;
  border: 1px solid #d63031;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin: 2px 10px !important;
  transform: translate(0, 0);
  font-size: 17px;
  font-weight: 600;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
}

.deleteBtn .yesDelete:hover {
  background-color: #f6f7f8;
  color: #d63031;
  border: 1px solid #d63031;
  font-size: 17px;
  font-weight: 600;
}

.deleteBtn .noDelete:hover {
  background-color: #f6f7f8;
  color: #0984e3;
  border: 1px solid #0984e3;
  font-size: 17px;
  font-weight: 600;
}

.requestButton a button:hover {
  box-shadow: 1px 1px 2px 1px #dbdbdb;
  transform: scale(1.01);
  -webkit-transform: scale(1.01);
  -moz-transform: scale(1.01);
  -ms-transform: scale(1.01);
  -o-transform: scale(1.01);
}

.myContactList {
  position: relative;
  display: flex;
  align-items: center;
  background: #f6f7f8;
  margin: 10px 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.myContactList .groupName {
  position: absolute;
  top: 0px;
  right: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #6f6f70;
}

.initialFiles button {
  border: none;
  background-color: #f94a29;
  color: #f6f7f8;
  padding: 4px 15px;
  text-align: center;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.modalItemListTable td:first-child {
  background-color: #fff !important;
}

.modalItemListTable td {
  background-color: rgb(255, 246, 246) !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #404040 !important;
  white-space: nowrap;
}

.replaceButton {
  display: flex;
  justify-content: end;
}

.replaceButton button {
  border: none;
  background-color: #f94a29;
  color: #f6f7f8;
  padding: 4px 15px;
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
}

.profileView .initialFiles {
  width: 125px;
  text-align: center;
}

.contactEmpty {
  margin-top: 10px;
  font-size: 15px;
  padding: 10px 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #f94a29;
  background-color: #f6f7f8;
}

.fileEmpty {
  font-size: 15px !important;
  padding: 10px 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500 !important;
  text-align: center;
  color: #f94a29 !important;
  background-color: #f6f7f8;
}

@media (-webkit-min-device-pixel-ratio: 1) and (max-width: 820px) {
  .tableData .dashboardSerial {
    justify-content: center;
  }

  .mobileTable {
    width: 8px;
  }

  .contactList {
    margin-right: 0px;
  }

  .paginationFooter {
    position: relative;
    text-align: center;
  }

  .rightSideTable {
    margin-top: 20px;
  }

  .topButtonContent {
    display: block;
  }

  .tableTopContent {
    flex-wrap: wrap;
  }

  .bottomPagination {
    display: flex;
    justify-content: center;
  }

  .filterBtn {
    margin-top: 18px;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }

  .filterBtn button {
    margin: 2px 8px;
    font-size: 14px;
  }

  .requestButton {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .sentButton {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 15px;
  }

  .accountBtn {
    width: 100%;
    margin: 4px 0px;
  }

  .tableSearch input {
    width: 100%;
  }

  .hint-text {
    font-size: 14px;
  }

  .tableData {
    padding-left: 10px;
    padding-right: 10px;
  }
}
