.sentFiles {
  min-height: calc(100vh - 80px);
  padding-top: 80px;
  background-color: #f8f8f8;
  padding-left: 80px;
  padding-right: 80px;
}

.sentHeading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #303030;
}

.sentList {
  height: calc(100vh - 323px);
  overflow-y: auto;
  padding: 0px 5px;
}

.sentList .table-responsive {
  height: calc(79vh - 200px);
  position: relative;
}

.sentList table {
  border-collapse: separate;
  border-spacing: 0 1em;
}

.sentList table thead {
  top: 0px;
  position: sticky;
  background-color: white;
  z-index: 9;
}

.rightTable {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 15px;
  height: 100%;
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.contactTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contactTop h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 19px;
  color: #303030;
}

.contactTop .searchName {
  width: 240px;
}

.addGroupButton button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  padding: 5px 8px;
  line-height: 15px;
  border: 1px solid #f94a29;
  color: #f94a29;
  background-color: #fff;
}

.addGroupButton button:nth-child(1) {
  margin-right: 5px;
}

.addGroupButton button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  padding: 5px 8px;
  line-height: 15px;
  border: 1px solid #f94a29;
  color: #f94a29;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.addContactInput {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 25px;
  position: relative;
  height: calc(100vh - 210px);
}

.addContactInput h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #303030;
  margin-bottom: 60px;
}

.addContactInput label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #a0a0a0;
}

.addContactInput input {
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  padding: 11px 20px;
  color: #747679;
  width: 100%;
  margin-bottom: 20px;
}

.addContact {
  text-align: center;
}

.addContact button,
.addContact p {
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  padding: 10px 70px;
  border: none;
  margin-bottom: 0px;
  cursor: pointer;
}

.addContact .back {
  background: #fff;
  color: #f94a29;
  border: 1px solid #f94a29;
}

.addContact .save {
  background: #f94a29;
  color: #ffffff;
}

.contactSearch input {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  padding: 13px 6px 13px 29px;
  color: #747679;
  border: 1px solid #dcdcdc;
}

.contactSearch svg {
  font-size: 12px;
}

.receiptList {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 25px;
  height: calc(100vh - 210px);
  position: relative;
  margin: 0px 15px;
}

.receiptList .table-responsive {
  height: calc(79vh - 220px);
  position: relative;
}

.receiptList .table-responsive table thead {
  top: 0px;
  position: sticky;
  background-color: white;
  z-index: 9;
}

.receiptList h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #303030;
  margin-bottom: 35px;
}

.groupBottom p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: #747679;
}

.groupList {
  display: flex;
}

.groupList .group {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  color: #f94a29;
  background: #ffffff;
  border: 1px solid #f94a29;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  margin-right: 15px;
  width: 100px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.groupList .individual {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  color: #747679;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  margin-right: 15px;
  border: none;
  width: 100px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.tableInnerAdd button {
  background: #f94a29;
  border: 1px solid #f94a29;
  border-radius: 3px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  color: #fff;
  padding: 3px 12px;
}

.settingModal h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #303030;
}

.customBox {
  margin-bottom: 20px;
}

.customBox label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a0a0a0;
}

.customBox textarea {
  width: 100%;
}

.modalInput label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a0a0a0;
}

.modalInput input {
  width: 100%;
  border: 1px solid #a0a0a0;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: rgba(116, 118, 121, 1);
  padding: 8px 10px;
}

.reminderBox {
  display: flex;
  justify-content: center;
}

.twoFA {
  display: flex;
  justify-content: end;
  padding-right: 35px !important;
}

.modalInput .dropdown {
  min-width: 222px;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: rgba(116, 118, 121, 1);
}

.remindersTime {
  width: 100% !important;
}

.dropButton.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: none !important;
  border-right: none !important;
  border-bottom: 0;
  border-left: none !important;
}

.dropButton {
  width: 100%;
  border: 1px solid #a0a0a0 !important;
  border-radius: 5px !important;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 18px !important;
  color: rgba(116, 118, 121, 1) !important;
  padding: 8px 10px;
  background-color: transparent !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  text-align: left !important;
  margin-bottom: 12px;
  height: 36px;
}

.dropButton svg {
  position: absolute;
  right: 10px;
}

.dropdownImg {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 18px !important;
  color: rgba(116, 118, 121, 1) !important;
}

.customBox textarea {
  width: 100%;
  border: 1px solid #a0a0a0;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: rgba(116, 118, 121, 1);
  padding: 8px 10px;
  height: 200px;
}

.settingModalBody .modal-body {
  padding: 56px 53px;
}

.recipientModalBody .modal-body {
  padding: 30px 5px;
}

.authShow {
  width: 60px !important;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #747679;
  margin-bottom: 8px;
  border: none;
  background: #f6f7f8;
}

.authShow input {
  width: 100% !important;
  padding: 0px !important;
  text-align: center !important;
}

.footerModalButton {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.footerModalButton .backDashboard {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #f94a29;
  background: #f8f8f8;
  border: 2px solid #f94a29;
  border-radius: 5px;
  width: 185px;
  padding: 10px 8px;
  margin: 2px 10px;
}

.footerModalButton .nextButton {
  background: #f94a29;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  width: 185px;
  padding: 10px 8px;
  border: none;
  margin: 2px 10px;
}

.requestModal {
  padding: 30px 20px;
}

.requestInput {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 100%;
}

.requestInput {
  padding: 20px 12px;
}

.requestInput h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 10px;
  color: #303030;
}

.requestInput label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a0a0a0;
  margin-bottom: 8;
}

.requestInput input {
  width: 100%;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #747679;
  padding: 8px 10px;
  border: none;
  background: #f6f7f8;
  border-radius: 5px;
}

.modalValue {
  width: 100%;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #747679;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  border: none;
  background: #f6f7f8;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin-bottom: 0px;
}

.recipientsBottom {
  margin-bottom: 28px !important;
}

.itemLine {
  display: flex;
  justify-content: space-between;
  padding: 15px 5px;
  align-items: center;
}

.itemLine p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 0px;
  color: #303030;
}

.itemLine button {
  background: #f94a29;
  border-radius: 2px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
  padding: 3px 7px;
  border: none;
  min-width: 28px;
}

.itemLine button p {
  font-weight: 600;
  font-size: 13px;
  color: #fff;
}

.itemSerial {
  height: 50vh;
  overflow-y: auto;
  padding-top: 30px;
}

.modalItem {
  position: relative;
  margin-bottom: 18px;
}

.itemSerial .requiredTopToggle {
  position: absolute;
  right: 10px;
  top: -10px;
  background: rgba(249, 74, 41, 0.06);
  border: 0.5px solid #f94a29;
  border-radius: 25px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 5px 15px;
  color: #f94a29;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.itemSerial .optionalTopToggle {
  position: absolute;
  right: 10px;
  top: -10px;
  background: rgba(116, 118, 121, 0.06);
  border: 0.5px solid #747679;
  border-radius: 25px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 5px 15px;
  color: #747679;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.itemSerial p {
  width: 100%;
  pointer-events: none;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #747679;
  padding: 10px 15px;
  margin-bottom: 8px;
  border: none;
  background: #f6f7f8;
}

.customMessageRequest label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a0a0a0;
}

.requestInput .itemLine {
  padding: 0px !important;
  margin-bottom: 12px;
}

.customMessageRequest textarea {
  width: 100%;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #a0a0a0;
  padding: 8px 10px;
  height: 80px;
  border: none;
  background: #f6f7f8;
  margin-bottom: 8px;
  pointer-events: none;
}

.customMessageRequestList label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a0a0a0;
  display: block;
  margin-bottom: 5px;
}

.customMessageRequestList input {
  width: 70%;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #747679;
  padding: 8px 15px;
  margin-bottom: 10px;
  border: none;
  background: #f6f7f8;
  pointer-events: none;
}

.nameEmail {
  height: 55vh;
  overflow-y: auto;
}

.nameEmail input {
  width: 100%;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #747679;
  padding: 8px 10px;
  margin-bottom: 8px;
  border: none;
  background: #f6f7f8;
}

.listEmail {
  width: 100%;
  padding: 8px 10px;
  margin-bottom: 8px;
  border: none;
  background: #f6f7f8;
  pointer-events: none;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.listEmail p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 2px;
  color: #747679;
}

.listEmail span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #747679;
}

.authToggle {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.authToggle p {
  margin-bottom: 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a0a0a0;
}

.authToggle .switch {
  position: relative;
  display: inline-block;
  width: 51px;
  height: 23px;
  margin: 0px 7px;
}

.authToggle .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.authToggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.authToggle .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

.authToggle input:checked + .slider {
  background-color: #f94a29;
}

.authToggle input:focus + .slider {
  box-shadow: 0 0 1px #f94a29;
}

.authToggle input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.authToggle .slider.round {
  border-radius: 34px;
}

.authToggle .slider.round:before {
  border-radius: 50%;
}

.saveTemplateHeading h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #303030;
  margin-bottom: 50px;
}

.loadTemplateHeading h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #303030;
  margin-bottom: 30px;
}

.templateModal {
  text-align: center;
}

.templateName {
  height: 50vh;
  overflow-y: auto;
  /* display: inline-block; */
  justify-content: center;
}

.deleteTemplateName {
  display: inline;
}

.templateName button {
  margin-top: 5px;
  background: #f6f7f8;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  min-height: 50px;
  color: #747679;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 40px;
  padding-left: 10px;
}

.templateName button:hover {
  background-color: #f94a29;
  color: #fff;
}

.templateName button span,
.templateName .deleteTemp {
  color: #f94a29;
  background-color: #fff;
}

.templateName button span,
.templateName .deleteTemp {
  position: absolute;
  right: 10px;
  top: 18px;
  color: #f94a29;
  font-weight: 600;
  border: 1px solid #f94a29;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.loadTemplateButton {
  position: relative;
}

.leftTable td {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #747679 !important;
}

.leftTable td p {
  margin-bottom: 0px;
}

.leftTable .tableCheckBox {
  width: 18px;
}

.tableMark {
  display: list-item;
}

.recipientName .fullName {
  white-space: nowrap;
}

.customMsg {
  height: 180px;
  overflow-y: auto;
  width: 100%;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #747679;
  padding: 8px 10px;
  border: none;
  background: #f6f7f8;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.countingNUmber {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  font-size: 20px;
  font-weight: 800;
  color: #f94a29;
}

.settingBox {
  padding: 0px 15px;
}

@media (-webkit-min-device-pixel-ratio: 1) and (max-width: 820px) {
  .sentFiles {
    padding-left: 0px;
    padding-right: 0px;
  }

  .contactTop {
    flex-wrap: wrap;
    justify-content: center;
  }

  .contactTop .searchName {
    margin-bottom: 10px;
  }

  .templateName button {
    width: 100%;
  }

  .mobileBottom {
    margin-bottom: 15px;
  }

  .titleWidth {
    width: 100% !important;
  }

  .reminderBox {
    justify-content: start;
  }

  .twoFA {
    display: flex;
    justify-content: start;
    padding-right: 0px !important;
  }
}
