.maintainSection {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  text-align: center;
}

.maintenance-icon {
  width: 100px;
  height: 100px;
  background-color: #f0f0ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
}
.maintenance-icon svg {
  width: 50px;
  height: 50px;
  color: rgb(249, 74, 41);
}
.maintainSection h1 {
  font-family: "Inter";
  font-size: 42px;
  font-weight: 600;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: center;
  color: rgb(249, 74, 41);
}
.maintainSection p {
  margin-top: 15px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  color: #303030;
}
