.paginationList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

@media (max-width: 778px) {
    .paginationList {
        justify-content: center;
    }
}