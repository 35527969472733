.contactPage {
  background-color: #f8f8f8;
  min-height: calc(100vh - 0px);
  position: relative;
  padding-top: 80px;
  padding-left: 80px;
  padding-right: 80px;
}

.contactHeading {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.contactHeading h1 {
  margin-right: 45px;
}

.contactHeading input {
  width: 350px !important;
}

.contactBox {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 15px;
  min-height: calc(100vh - 110px);
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.contactBtnList button {
  background: rgb(249, 74, 41);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(249, 74, 41);
  border-radius: 5px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding: 1px 17px;
  height: 40px;
  margin: 0px 5px;
  position: relative;
  overflow: hidden;
  animation: 1s ease 0s 1 normal backwards running fadeInUp;
}

.contactFilter {
  margin-top: 20px;
}

.contactFilter button {
  background: #ffffff;
  color: #747679;
  box-shadow: 0px 0px 10px 0px #0000001a;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  margin-right: 10px;
  padding: 6px 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.contactFilter button.active {
  border: 1px solid #f94a29;
  color: #f94a29;
}

.contactDownload {
  display: flex;
  align-items: center;
}

.contactDownload button {
  width: 140px;
  padding: 9px 0px;
  border: 2px solid #f94a29;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #f94a29;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px #0000001a;
  margin-right: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.contactListTable {
  height: calc(100vh - 323px);
  padding: 0px 5px;
}

.contactListTable .table-responsive {
  height: calc(79vh - 200px);
  position: relative;
}

.contactListTable table {
  border-collapse: separate;
  border-spacing: 0 1em;
}

.contactListTable table thead {
  top: 0px;
  position: sticky;
  background-color: white;
  z-index: 9;
}

.contactPagination {
  position: absolute;
  bottom: 10px;
  left: 0px;
  width: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.addContactForm {
  padding: 40px 50px;
}

.addContactBtn {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contactCondition {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
}

.shared {
  display: flex;
  padding: 7px 4px;
}

.shared p {
  margin-left: 25px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #747679;
  margin-bottom: 0px;
}

.groupAdd p {
  background: #ffffff;
  border: 1px solid #f94a29;
  color: #f94a29;
  padding: 10px 30px;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}

.groupText h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: rgb(106, 106, 106);
  margin-top: 15px;
}

.contactContent {
  padding: 10px 25px;
}

.contactContent p {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #747679;
}

.addContactForm h1 {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #303030;
  margin-bottom: 50px;
}

.groupModal {
  padding: 40px 20px;
}

.groupListItem {
  overflow-y: auto;
  max-height: 60vh;
  margin-top: 25px;
}

.groupModal h1 {
  font-family: "Inter";
  font-size: 26px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #303030;
  margin-bottom: 10px;
}

.groupNameList {
  font-size: 18px;
  line-height: 15px;
}

.radio-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.radio-list label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radio-list input[type="radio"] {
  margin-right: 5px;
}

.radio-list input[type="radio"]:checked+label {
  color: #f94a29;
}

.timeCheckbox {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 15px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.timeCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.timeCheckbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

.timeCheckbox:hover input~.checkmark {
  background-color: #ccc;
}

.timeCheckbox input:checked~.checkmark {
  background-color: #f94a29;
}

.timeCheckbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.timeCheckbox input:checked~.checkmark:after {
  display: block;
}

.timeCheckbox .checkmark:after {
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.paginationBottom {
  height: 32px;
}

.contactPagination .contactPagList {
  display: flex;
  align-items: center;
  width: 100%;
}

.exportFileBox {
  padding: 20px;
}

.exportFileBox h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  color: #303030;
  text-align: center;
}

.exportFileBox .fileSave button {
  text-align: center;
  background: rgb(249, 74, 41);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(249, 74, 41);
  border-radius: 5px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding: 1px 17px;
  height: 40px;
  margin: 0px 5px;
  position: relative;
  overflow: hidden;
  animation: 1s ease 0s 1 normal backwards running fadeInUp;
}

.addGroupForm {
  padding: 40px 50px;
}

.addGroupForm h1 {
  font-family: "Inter";
  font-size: 25px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #303030;
}

.addGroupForm h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 24px;
  color: #303030;
  margin-top: 20px;
}

.groupTable {
  max-height: 500px;
  overflow-y: auto;
}

.groupTable .tableBody tbody td:last-child {
  text-align: left !important;
}

.contactNumber {
  position: relative;
  margin-top: 11px;
}

.phoneNo {}

.contactNumber label,
.phoneNo label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #A0A0A0;
  margin-bottom: 3px;
}

.contactNumber .react-tel-input .form-control,
.phoneNo .react-tel-input .form-control {
  width: 100%;
  height: 40px;
}

.contactNumber .react-tel-input .form-control:focus,
.phoneNo .react-tel-input .form-control:focus {
  border: 1px solid #D2D2D2 !important;
}

.contactTemplate {
  display: flex;
  justify-content: end;
}

.contactTemplate button {
  text-align: center;
  background: rgb(249, 74, 41);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(249, 74, 41);
  border-radius: 5px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  padding: 1px 13px;
  height: 40px;
  margin: 0px 5px;
  position: relative;
  margin-bottom: 10px;
}

@media (max-width: 820px) {
  .contactPage {
    min-height: 100vh;
    padding-top: 80px;
    background-color: #f8f8f8;
    padding-left: 0px;
    padding-right: 0px;
  }

  .contactHeading {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  .contactBtnList {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .contactBtnList button {
    font-size: 14px;
  }

  .paginationBottom {
    height: auto;
    margin-top: 25px;
  }

  .contactPagination .contactPagList {
    flex-wrap: wrap;
    justify-content: center;
  }

  .contactBox {
    min-height: calc(100vh - 0px);
  }
}