.confirmMsg {
  max-width: 778px;
  width: 100%;
  text-align: center;
  box-shadow: 0px 0px 40px 1px #0000001a;
  background: #ffffff;
  padding-top: 50px;
  padding-bottom: 80px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.confirmMsg h1 {
  font-family: "Inter";
  font-size: 32px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  color: #303030;
}

.confirmMsg h2 {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #303030;
}
.paymentContinue {
  margin-top: 50px;
}
.paymentContinue a {
  background: #f94a29;
  box-shadow: 2px 6px 20px 2px #00000026;
  color: #ffffff;
  padding: 17px 80px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  text-decoration: none;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.confirmMsg svg {
  width: 100px;
  display: block;
  margin: 40px auto 0;
}

.confirmMsg .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.confirmMsg .path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}
