.footer {
    background: #F6F6F6;
    padding: 90px 0px;
}

.footer .footerLine {
    display: flex;
    justify-content: space-around;
}

.footerLogo img {
   margin-bottom: 20px;
}

.footerLogo p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #303030;
    margin-bottom: 0px;
}

.footerLogo .email {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    text-decoration: none;
    color: #F94A29;
    text-decoration: underline;
}

.footerLink h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    text-align: left;
    color: #303030;
}

.footerLink a {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: -0.04em;
    color: #303030;
    text-decoration: none;
    display: block;
}

.footerLink svg {
    color: #F94A29;
    font-size: 15px;
    padding-right: 10px;
    width: 18px;
}

.footerLink a:hover {
    color: #F94A29;
}

@media (-webkit-min-device-pixel-ratio: 1) and (max-width:778px) {
    .footer {
        text-align: center;
    }

    .footerLink h1 {
        text-align: center;
        margin-top: 25px;
    }

    .socialLinkButton a {
        margin-left: 50%;
        transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        text-align: left;
        width: 95px;
    }

    .footer .footerLine {
        display: block;
    }
}