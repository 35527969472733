.settingPage {
  min-height: 100vh;
  padding-top: 80px;
  background-color: #f8f8f8;
  padding-left: 80px;
  padding-right: 80px;
  background-color: #f8f8f8;
  min-height: calc(100vh - 0px);
  position: relative;
  padding-top: 80px;
  padding-left: 50px;
  padding-right: 50px;
}

.securityBoxLine {
  margin-top: 25px;
  display: flex;
}

.sharedBox {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 30px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px #0000001a;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}


.sharedBox p {
  position: relative;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
  margin-bottom: 0px;
}

.sharedBox svg {
  font-size: 5px;
  border: 1px solid #303030;
  padding: 1px;
  border-radius: 50%;
  width: 9px;
  height: 9px;
}

.securityAlert {
  margin-top: 25px;
  min-height: 270px;
  padding: 22px 26px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px #0000001a;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.securityAlert h2,
.logSetting h2 {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
}

.alertList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;
}

.alertList p {
  font-family: "Inter";
  width: 192px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
  margin-bottom: 0px;
}

.alertList .securityDropdown {
  background: #f6f7f8;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #747679;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  width: 110px;
}

.securityDropdown .dropdownButton {
  width: 100%;
  border: 1px solid #f6f7f8 !important;
  border-radius: 5px !important;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 18px !important;
  color: rgba(116, 118, 121, 1) !important;
  padding: 8px 10px;
  background-color: transparent !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  text-align: left !important;
  height: 36px;
  background: #f6f7f8;
}

.securityDropdown .dropdown-menu {
  min-width: 110px !important;
}

.logSetting {
  margin-top: 25px;
  min-height: 270px;
  padding: 22px 30px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px #0000001a;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.tooltipArrow {
  position: relative;
  display: inline-block;
  margin-left: 6px;
}

.tooltipArrow .tooltipText {
  visibility: hidden;
  background-color: rgb(255, 255, 255);
  border: 1px solid #f94a29;
  color: #f94a29;
  font-family: "Inter" !important;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  font-size: 14px;
  line-height: 21px;
}

.longContent {
  min-width: 420px;
  margin-left: -210px;
}

.shortContent {
  min-width: 330px;
  margin-left: -166px;
}

.tooltipArrow .tooltipText a {
  color: #f94a29;
  text-decoration: underline;
  font-weight: 600;
}

.tooltipArrow .tooltipText::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #f94a29 transparent transparent transparent;
}

.tooltipArrow:hover .tooltipText {
  visibility: visible;
}

.securityBtn {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.securityBtn button {
  background: rgb(249, 74, 41);
  box-shadow: rgba(0, 0, 0, 0.15) 2px 6px 20px 2px;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgb(255, 255, 255);
  padding: 14px 30px;
  border: 1px solid rgb(249, 74, 41);
}

.adminHeading button,
.adminHeading a {
  background: #f94a29;
  color: #ffffff;
  font-family: "Inter";
  padding: 10px 20px;
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  text-decoration: none;
}

.securityQuestions p {
  font-family: "Inter";
  font-size: 19px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #4d4d4d;
  margin-bottom: 20px;
}

.adminHeading button,
.adminHeading a {
  background: #f94a29;
  color: #ffffff;
  font-family: "Inter";
  padding: 10px 20px;
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  text-decoration: none;
}


.securityQuestions h1 {
  font-family: "Inter";
  font-size: 30px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #303030;
}

.QrImageBox {
  padding: 25px;
  text-align: center;
}

.qrImageShow button {
  display: block;
  background: #f94a29;
  color: #ffffff;
  font-family: "Inter";
  padding: 10px 20px;
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  text-decoration: none;
}

.qrImageShow h6 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #303030;
}

.qrImageShow h4 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #303030;
}

.activeSwitch,
.activeSwitch input {
  cursor: none !important;
  pointer-events: none;
}

@media (max-width: 778px) {
  .sharedBox {
    margin-right: 0px;
  }

  .logSetting {
    margin-left: 0px;
  }
}