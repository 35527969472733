.Auth-form-container {
    width: 100%;
    min-height: 100vh;
    padding: 5vh 0vh 5vh 5vh;
    background-color: #f8f8f8;
}

.loginBox {
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    min-height: 90vh;
    display: flex;
    padding: 20px 0px 20px 20px;
}

.loginForm {
    width: 50%;
    text-align: center;
}

.loginForm img {
    margin-top: 20px;
    width: auto;
}

.loginForm h1 {
    color: #F94A29 !important;
    font-size: 43px;
    font-weight: 700;
    letter-spacing: 1px;
    text-align: center;
}

.Auth-form {
    border-radius: 8px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.Auth-form-content {
    width: 400px;
}


.Auth-form-content h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    color: #303030;
    margin-bottom: 50px;
}

.loginInput {
    margin-top: 15px;
    position: relative;
    display: flex;
    align-items: center;
}

.loginInput input {
    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    border-radius: 5px;
    width: 100%;
    height: 50px;
    padding: 0px 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: rgb(54, 54, 54);

}

.loginInput span {
    position: absolute;
    right: 15px;
    color: #747679;
    font-size: 12px;
    cursor: pointer;
}

.loginInput input:focus {
    box-shadow: none;
    border-color: none !important;
}

.forgot-password {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

.forgot-password button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #F94A29;
    text-decoration: none;
    background-color: transparent;
    border: none
}

.forgot-password a {
    color: #F94A29;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    text-decoration: none;
    background-color: transparent;
    border: none
}

.forgot-password a:hover {
    color: #F94A29;
}
.registrationBtn{
    padding-top: 25px;
}
.registrationBtn p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px !important;
    line-height: 17px;
    color: #A0A0A0;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 20px;
}

.registrationBtn button {
    background: #F94A29;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    width: 100%;
    height: 50px;
    border: none;
}

.forgetBtn {
    margin-top: 30px;
    background-color: #F94A29;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    width: 100%;
    height: 50px;
    border: none;
    margin-left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
}

.loginContent {
    background: #FFEFEC;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: right;
    align-items: center;
    width: 50%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.dashImage {
    position: relative;
}

.dashImage img {
    width: 100%;
}

.errMsg {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #747679;
}

.forgetTitle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    color: #F94A29;
}

.falseLogin {
    pointer-events: none;
}

.appInstruction h6{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 30px;
    text-align: center;
    color: #303030;
}

.appInstruction ul li{
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #303030;
}
.appInstruction .resend{
    margin-top: 10px;
    padding: 8px 28px;
    border-radius: 20px;
    height: 40px;
    background-color: #F94A29 !important;
    color: #fff;
    /* width: 140px; */
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border: none;
    cursor: pointer;
}
.upgradeBtn{

  border-radius: 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #F94A29;
  width: 100%;
  border: 1px solid #F94A29;
  margin-bottom: 0px;
  margin-top: 15px;
  display: ruby-base;
  padding: 4px 12px;
  cursor: pointer;
}
@media (-webkit-min-device-pixel-ratio: 1) and (max-width: 778px) {
    .Auth-form-container {
        padding: 0px;
    }

    .loginBox {
        flex-wrap: wrap;
        padding: 5px;
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
    }

    .loginForm,
    .loginContent {
        width: 100%;
        height: 100%;
    }

    .Auth-form-content {
        width: 100%;
        padding: 8px;
    }

    .loginContent {
        margin-top: 15px;
        border: 0px;
    }
}