body {
  background-color: #fff !important;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border: 1px solid #424242 !important;
  /* border: none !important; */
  outline: 0;
  box-shadow: none !important;
}

input:focus,
textarea:focus,
select:focus {
  outline: none !important;
}

/* .homePage .container {
  padding: 0px 40px !important;
} */

.tableData .container,
.tableData .container-fluid,
.tableData .container-lg,
.tableData .container-md,
.tableData .container-sm,
.tableData .container-xl,
.tableData .container-xxl,
.createRequest .container,
.createRequest .container-fluid,
.createRequest .container-lg,
.createRequest .container-md,
.createRequest .container-sm,
.createRequest .container-xl,
.createRequest .container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-right: auto;
  margin-left: auto;
}

.tableData .row,
.createRequest .row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: 0px !important;
  margin-left: 0px !important;
}

/* .row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-top: var(--bs-gutter-y);
} */

@media (min-width:1200px) {

  .tableData .container,
  .tableData.container-lg,
  .tableData .container-md,
  .tableData .container-sm,
  .tableData .container-xl {
    max-width: 100% !important;
  }
}

@media (min-width:1400px) {

  .tableData .container,
  .tableData .container-lg,
  .tableData .container-md,
  .tableData .container-sm,
  .tableData .container-xl,
  .tableData .container-xxl {
    max-width: 100% !important;
  }
}

@media (-webkit-min-device-pixel-ratio: 1) and (max-width: 780px) {
  .homePage .container {
    padding: 0px 20px !important;
  }
}