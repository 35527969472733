.ml-2 {
  margin-left: 7px;
}

.planBox {
  min-width: 520px;
  background: #ffefec;
  padding-top: 18px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 38px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  margin-left: 15px;
}

.planBox h1 {
  font-family: "Inter";
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
}

.planBox h2 {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
}

.featureLine h1 {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
  margin-bottom: 7px;
}

.featureLine h1 svg {
  color: #f94a29;
  padding-right: 4px;
}

.redLine {
  height: 1px;
  width: 100%;
  background: #f94a29;
  margin-top: 22px;
}

.priceTableList {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.leftPriceContent h1 {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
  margin-bottom: 0px;
}

.leftPriceContent h2 {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
  margin-bottom: 0px;
}

.leftPriceContent p {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6a6a6a;
  margin-bottom: 0px;
}

.leftPriceContent .redText {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #f94a29;
}

.rightPriceText h1 {
  font-family: "Inter";
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: right;
  color: #303030;
  margin-bottom: 0px;
}

.rightPriceText h2 {
  font-family: "Inter";
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: right;
  color: #f94a29;
}

/* Collapsible content */
.collapsible-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

/* Collapsible content when open */
.collapsible-content.open {
  max-height: 1000px; /* Adjust to your desired open height */
}
@media (-webkit-min-device-pixel-ratio: 1) and (max-width:1180px) {
    .planBox {
        margin-left: 0px;
        margin-top: 25px;
         min-width: 100% !important;
      }
      .registration-form{
        margin-right: 0px;
        padding: 45px 25px 46px 25px;
      }
     
}